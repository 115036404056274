import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
import {NavigationStart, Router } from '@angular/router';
import { FreeTrialRegisterRequest, RegisterRequestModel } from 'src/app/models/loginrequest';
import { AccountService } from 'src/app/_services/account.service';
import { TransferdataService } from "src/app/transferdata.service";
//todo15
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as moment from 'moment';
import { delay } from 'rxjs/operators';

export let browserRefresh = false;

@Component({
  selector: 'app-additionaladdon',
  templateUrl: './additionaladdon.component.html',
  styleUrls: ['./additionaladdon.component.css']
})

export class AdditionaladdonComponent implements OnInit {
  subscription: Subscription;
  isFreeTrial :boolean;
  isSubscription :boolean;
  freeTrialDaysdays:any ;
  firsSubscripptionPrice:any ;
  secondSubscriptionPrice:any ;
  plan_qty:number=0;
  addonDevice:any;
  finalPrice :any = 0;
  btnRegisterText : string = 'Continue';
  totalDevice:any=0;
  totalSubscription:any
  gtotalSubscription1:any
  gfirsSubscripptionPrice:any ;
  gsecondSubscriptionPrice:any ;
  gtotalSubscription:any
  gtotalDevice:number=0;
  gDeviceCount:number=0;
  DatabaseRemainingValue:any
//todo9Dec-23
bronzeBasePrice:any;
bronzeAddonPrice:any;
bronzeDevices:any
bronzeDevices1: any;

UserbronzeBasePrice:any;
UserbronzeAddonPrice:any;
UserbronzeDevices:any
UserbronzeDevices1: any;
 //Bronze dated 8Dec-23
 onedaycostofbronzebaseDevice:any;
 onedaycostofbronzeAddonDevice:any
 CurrentbronzeSubscriptionRemainingDaysAmount:any

//todo27-12-22
  
  registerUserModel:RegisterRequestModel;
  totalSubscription1: any;
  selectValue: any;
  silverBasePrice:any;
  silverAddonPrice:any;
  silverDevices:any
  silverDevices1: any;
//todorekha
 message:string="";
 approvalText:string;
 finalcostfromSubsUI:string;
  UsersilverBasePrice:any;
  UsersilverAddonPrice:any;
  UsersilverDevices:any;
  UsersilverDevices1:any;
  userPlan:any;

  userfirsSubscripptionPrice:any;
  usersecondSubscriptionPrice:any;
  usertotalSubscription:any;
  usertotalSubscription1:any;
 
  guserfirsSubscripptionPrice:any;
  gusersecondSubscriptionPrice:any;
  gusertotalSubscription:any;
  gusertotalSubscription1:any;
  OneGoldAddonPrice:any;
  NoteValue:string;
  NoteValue1:string;
  SetAgaintheExistingPlan : string;
  ValidityofSubscription: any;
  CheckRemaingDays : any;
  OldAddonDevices :any;
  AdditionalDeviceCost : any;
  NoAdditionalDevice : any;
  OneDeviceCost : any
  remainingAmount: any;
  currentdate: any;
  registeredEamiId: string;
  balanceAmount12: any;
  NoteValueforremainingAmount:string;
  showHideRemainingAmountnote:boolean=false;
  loginName:any

  constructor(private ngxService: NgxUiLoaderService,private route:Router,private service:AccountService,private servicedata:TransferdataService
   ) { 
  //   this.subscription = route.events.subscribe((event) => {
  //     if (event instanceof NavigationStart) {
  //       browserRefresh = !route.navigated;
  //     }
  // });

   }   
  
   //todo2/1/23
  async ngOnInit(): Promise<void> {
    debugger
    // this.servicedata.currentApprovalStageMessage.subscribe(msg => this.message = msg);
    // this.servicedata.selectedPlanPricermessage.subscribe(msg => this.finalcostfromSubsUI = Number(msg).toFixed(2));
    // this.servicedata.sendRegisteredEmailIdAsMessage.subscribe(msg=>this.registeredEamiId=msg);

//6Nov23
this.servicedata.currentApprovalStageMessage.subscribe(msg => this.message = msg);
if(this.message=="Basic device is required!")
{
 this.message = localStorage.getItem('uMassage') as string;
}
else
{
 localStorage.setItem('uMassage', this.message.toString());

}
this.servicedata.selectedPlanPricermessage.subscribe(msg => this.finalcostfromSubsUI = Number(msg).toFixed(2));
if(this.finalcostfromSubsUI=="NaN")
 {
  this.finalcostfromSubsUI = localStorage.getItem('uFinalPrise') as string;
 }
 else
 {
  localStorage.setItem('uFinalPrise', this.finalcostfromSubsUI.toString());

 }
this.servicedata.sendRegisteredEmailIdAsMessage.subscribe(msg=>this.registeredEamiId=msg);
 if(this.registeredEamiId=="N/A")
 {
  this.registeredEamiId= localStorage.getItem('uEmail') as string;
 }
 else
 {
  localStorage.setItem('uEmail', this.registeredEamiId.toString());

 }
 //to get username
 this.servicedata.sendLoginUserNameAsMessage.subscribe(msg=>this.loginName=msg);
 if(this.loginName=="N/A")
 {
  this.loginName= localStorage.getItem('uName') as string;
 }
 else
 {
  localStorage.setItem('uName', this.loginName.toString());

 }
    this.ngxService.start();
    this.getSubscriptionDetails();
      delay(1000);
    //todo3jan-2023
    this.getUserSubscriptionDetails();
    
    this.ngxService.stop();
    
    debugger
       
    this.isSubscription=true;
    //to hide StepperValue
   
     this.service.model.subscribe(data => {
      console.log("transfereddata",data);
      this.registerUserModel = data;
    });
     if((this.finalcostfromSubsUI="NaN")&&(this.registeredEamiId=="N/A")){
      alert("Network error, Please try again!")
         this.ngxService.start();
          this.route.navigate(['login']);
          this.ngxService.stop();
     }
     
  }
  
  //this function is used to get Subscription Deatil.
  getSubscriptionDetails(){
    this.ngxService.start();
    var subsciptionDetails = this.service.getSupsriptionPrice().subscribe(res=>{
      debugger   
      if(res.ResponseCode == 200){
       if(res.Data[0].PlanId==1)
       {
           this.silverBasePrice=this.firsSubscripptionPrice=res.Data[0].PriceofSubscription;
           this.silverAddonPrice=this.secondSubscriptionPrice= res.Data[0].AddonPrice;
            this.silverDevices=this.totalSubscription= res.Data[0].NoofDevices;
            
            this.silverDevices1=this.totalSubscription1=res.Data[0].NoofDevices
            this.NoteValue="Note: Each additional device will adjust 1 with (+/-) button.";
            
          }
                
          if(res.Data[1].PlanId==2){

            this.gfirsSubscripptionPrice=this.firsSubscripptionPrice=res.Data[1].PriceofSubscription;
            this.gsecondSubscriptionPrice =this.secondSubscriptionPrice= Number(res.Data[1].AddonPrice)*5;
            this.gtotalSubscription=this.totalSubscription= res.Data[1].NoofDevices;
            this.gtotalSubscription1 =this.totalSubscription1=res.Data[1].NoofDevices;
            this.NoteValue="Note: Each additional device will adjust 5 with (+/-) button.";
          }

           //added todo9Dec for Bronze Subscription

           if(res.Data[2].PlanId==3)
           {
              this.bronzeBasePrice=this.firsSubscripptionPrice=res.Data[2].PriceofSubscription;
              this.bronzeAddonPrice=this.secondSubscriptionPrice= res.Data[2].AddonPrice;
              this.bronzeDevices=this.totalSubscription= res.Data[2].NoofDevices;
              this.bronzeDevices1=this.totalSubscription1=res.Data[2].NoofDevices
              this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
             
            }
             
        }
 
      },(err:HttpErrorResponse)=>{
       alert("This is the error response for the otp verification");
      }
    )
    this.ngxService.stop();
  }
  //todo 3jan-23
    //this function is used to get  registered UserSubscription Deatil.
   getUserSubscriptionDetails(){
    this.ngxService.start();
    var subsciptionDetails = this.service.getuserSupsriptionPrice().subscribe(res=>{
       debugger  
      if(res.ResponseCode == 200){
        debugger
       if(res.Data[0].AddonPrice!=null){

       if(res.Data[0].PlanName=="Silver Subscription"){
           this.SetAgaintheExistingPlan=this.userPlan=res.Data[0].PlanName;
           this.selectValue =1;
           this.UsersilverBasePrice=this.firsSubscripptionPrice=this.silverBasePrice;
           this.totalSubscription=this.silverDevices;

           this.secondSubscriptionPrice=Number(this.silverAddonPrice).toFixed(2);
           this.UsersilverAddonPrice= Number(res.Data[0].AddonPrice).toFixed(2);
           this.totalDevice=this.UsersilverAddonPrice;
           this.finalPrice=Number(res.Data[0].Price1).toFixed(2).toString();
           this.DatabaseRemainingValue=this.remainingAmount=Number(res.Data[0].Remainingamount).toFixed(2).toString();
           //this.finalPrice=(Number(this.UsersilverAddonPrice) + Number(this.firsSubscripptionPrice)).toFixed(2).toString();
           this.UsersilverDevices=this.totalSubscription1= res.Data[0].NumberOfSubscriber;
           this.OldAddonDevices=this.addonDevice=res.Data[0].AddonDevice;
           this.ValidityofSubscription=new String(res.Data[0].Validity).substring(0,10);
           this.NoteValue="*Note: Each additional device will adjust 1 with (+/-) button.";
           }
                
        if(res.Data[0].PlanName=="Gold Subscription")
          {
            this.SetAgaintheExistingPlan= this.userPlan=res.Data[0].PlanName;
            this.selectValue =5
            this.userPlan="Gold Subscription"
            this.plan_qty=0
            this.addonDevice=0;
            this.firsSubscripptionPrice=this.gfirsSubscripptionPrice
            this.secondSubscriptionPrice=this.gsecondSubscriptionPrice;
            this.totalDevice=this.gtotalDevice
            this.totalSubscription=this.gtotalSubscription
           // this.onedayCost=this.gfirsSubscripptionPrice/30;
            //this.finalPrice=this.onedayCost*this.CheckRemaingDays;
             //this.finalPrice=this.finalPrice.toFixed(2);
            this.finalPrice=this.gfirsSubscripptionPrice
            this.totalSubscription1=this.gtotalSubscription1



           // this.selectValue =5;
           //this.guserfirsSubscripptionPrice= this.firsSubscripptionPrice=this.gfirsSubscripptionPrice;
            //this.gusersecondSubscriptionPrice=Number(this.secondSubscriptionPrice)*5;
            //this.totalDevice=this.gusersecondSubscriptionPrice;
           // this.finalPrice=Number(res.Data[0].Price1).toFixed(2).toString(); 
            this.DatabaseRemainingValue=this.remainingAmount=Number(res.Data[0].Remainingamount).toFixed(2).toString();  
            //this.finalPrice=(Number(this.gusersecondSubscriptionPrice) + Number(this.firsSubscripptionPrice)).toFixed(2).toString();
            this.OldAddonDevices=this.addonDevice=res.Data[0].AddonPrice;   
        
            this.ValidityofSubscription=new String(res.Data[0].Validity).substring(0,10);               
            this.gusertotalSubscription1=this.totalSubscription1 =res.Data[0].NumberOfSubscriber;
            this.NoteValue="*Note: Each additional device will adjust 5 with (+/-) button.";
          }



          if(res.Data[0].PlanName=="Bronze Subscription"){
            this.SetAgaintheExistingPlan=this.userPlan=res.Data[0].PlanName;
            this.selectValue =1;
            this.UserbronzeBasePrice=this.firsSubscripptionPrice=this.bronzeBasePrice;
            this.totalSubscription=this.bronzeDevices;
 
            this.secondSubscriptionPrice=Number(this.bronzeAddonPrice).toFixed(2);
            this.UserbronzeAddonPrice= Number(res.Data[0].AddonPrice).toFixed(2);
            this.totalDevice=this.UserbronzeAddonPrice;
            this.finalPrice=Number(res.Data[0].Price1).toFixed(2).toString();
           this.DatabaseRemainingValue= this.remainingAmount=Number(res.Data[0].Remainingamount).toFixed(2).toString();
            //this.finalPrice=(Number(this.UsersilverAddonPrice) + Number(this.firsSubscripptionPrice)).toFixed(2).toString();
            this.UserbronzeDevices=this.totalSubscription1= res.Data[0].NumberOfSubscriber;
            this.OldAddonDevices=this.addonDevice=res.Data[0].AddonDevice;
            this.ValidityofSubscription=new String(res.Data[0].Validity).substring(0,10);
            this.NoteValue="*Note: Each additional device will adjust 1 with (+/-) button.";
            }
                
         }  
       else{
           
           this.selectValue =1;
           this.firsSubscripptionPrice=this.silverBasePrice;
           this.secondSubscriptionPrice= this.silverAddonPrice;
            this.totalSubscription= this.silverDevices;
            this.totalSubscription1=this.silverDevices1;
            this.finalPrice=this.silverBasePrice;
            this.NoteValue="*Note: Each additional device will adjust 1 with (+/-) button.";

        } 
        debugger  
         const TempplanValidity= new Date(this.ValidityofSubscription);
         const now= new Date();
         this.ValidityofSubscription = moment(this.ValidityofSubscription);
         this.currentdate = moment(now);
         this.CheckRemaingDays = Math.abs(this.ValidityofSubscription.diff(this.currentdate, 'days')); 
         this.CheckRemaingDays= Number(this.CheckRemaingDays);
         this.CheckRemaingDays= Number(this.CheckRemaingDays) +2;
         console.log("no of days=",this.CheckRemaingDays); 
         this.plan_qty = Number(this.addonDevice); 

      this.ngxService.stop();      
      if(Number(this.remainingAmount>0)){
        this.showHideRemainingAmountnote=true;
       this.NoteValueforremainingAmount="*Remaining amount of this subscription is = $ "+ this.remainingAmount;  
      }
      else{
        this.remainingAmount="0";
        this.showHideRemainingAmountnote=false;
      }
    }

    },(err:HttpErrorResponse)=>{
    alert("this is the error response for the otp verification");
    }
    
    )
  
  }
   
  //this function used to reset the all value.

  resetValue(){

    this.finalPrice=0
   }

  calculateTotalPrice(price:number, noOfSubscription:number):number{
       var value= noOfSubscription * price;
    //todo fix the decimal value
    var totalPrice :number=  parseInt(parseFloat(value.toString()).toFixed(2));
    return   value ;
  }
   
  //this function is used for add the addinaladdon devices in existing plan subscription.
  plus()
  { 
    debugger
   if(this.SetAgaintheExistingPlan=="Gold Subscription")
   {
    //one additional device Cost
    this.OneDeviceCost= Number(this.secondSubscriptionPrice)/30;
    this.OneDeviceCost=this.OneDeviceCost;
    //this.OneDeviceCost=this.OneDeviceCost.toFixed(2);
    //this.OneDeviceCost=this.OneDeviceCost-0.01;
    this.selectValue=5;
    this.totalSubscription=this.gtotalSubscription1;
    
   }
   else 
   {
    this.OneDeviceCost= Number(this.secondSubscriptionPrice)/30;
    this.OneDeviceCost=this.OneDeviceCost;
    //this.OneDeviceCost=this.OneDeviceCost.toFixed(2);
    //this.OneDeviceCost=this.OneDeviceCost-0.01;
    if(this.userPlan=='Silver Subscription')
    {
      this.totalSubscription=this.UsersilverDevices;
    }
    else{
      this.totalSubscription=this.UserbronzeDevices
    }
    this.selectValue=1;
   }
   debugger
   if(this.plan_qty>=5 && this.selectValue==1){

    alert("You have reached maximum number of add-on devices.")
        //  this.ngxService.start();
        //   this.route.navigate(['subscriptiondetails']);
        //   this.ngxService.stop();

   }
   else  if(this.plan_qty>=4 && this.selectValue==1 && this.userPlan=='Bronze Subscription'){

    alert("You have reached maximum number of add-on devices.")
        //  this.ngxService.start();
        //  window.location.reload();
        //   this.ngxService.stop();

   }
   else if(this.plan_qty>=50 && this.selectValue==5){

         alert("You have reached maximum number of add-on devices.")
        //  this.ngxService.start();
        //  window.location.reload();
        //   this.ngxService.stop();
   }

   //add in the other
   else{
   this.plan_qty = Number(this.addonDevice)+ this.selectValue;
   //this.defaultPrice();
   this.NoAdditionalDevice=this.plan_qty-this.OldAddonDevices;
   this.addonDevice=Number(this.OldAddonDevices) + Number(this.NoAdditionalDevice);
   //Cost of added Addon device=AddedDevices * No of remaing day * Cost of one day price of idevices.
   //this.AdditionalDeviceCost = this.NoAdditionalDevice *Number(this.OneDeviceCost) * Number(this.CheckRemaingDays);
   this.AdditionalDeviceCost = Number(this.OneDeviceCost) * Number(this.CheckRemaingDays);
   this.AdditionalDeviceCost=this.AdditionalDeviceCost.toFixed(2);

   //this.totalDevice = this.addonDevice * this.secondSubscriptionPrice
   this.NoteValue= " Note: You have added "+ this.NoAdditionalDevice +" additional add-on devices for "+ this.CheckRemaingDays +" days, and you have to pay $ "+ this.AdditionalDeviceCost + " per additional add-on device to continue with existing plan.";
   this.totalDevice= Number(this.totalDevice) + Number(this.AdditionalDeviceCost);
   this.totalDevice=Number(this.totalDevice).toFixed(2);

   //this.totalSubscription1 =  Number(this.totalSubscription ) +  this.addonDevice;
   this.totalSubscription1 =  Number(this.totalSubscription ) + Number(this.NoAdditionalDevice);//added 9feb24
   this.finalPrice= Number (this.AdditionalDeviceCost) + Number(this.finalPrice)
   this.finalPrice=Number(this.finalPrice).toFixed(2);
    }
  }
   // }
 //This function used to  minus addon device according to subscription.
  minus()
  {
  
  debugger
    if(this.SetAgaintheExistingPlan=="Gold Subscription")
   {
    //one additional device Cost
    this.OneDeviceCost= Number(this.secondSubscriptionPrice)/30;
    this.OneDeviceCost=this.OneDeviceCost;
    //this.OneDeviceCost=this.OneDeviceCost.toFixed(2);
    //this.OneDeviceCost=this.OneDeviceCost-0.01;
    this.selectValue=5;
   }
   else
   {
    this.OneDeviceCost= Number(this.secondSubscriptionPrice)/30;
    this.OneDeviceCost=this.OneDeviceCost;
    //this.OneDeviceCost=this.OneDeviceCost.toFixed(2);
   // this.OneDeviceCost=this.OneDeviceCost-0.01;
    this.selectValue=1;
   }
   debugger
   if(this.plan_qty > this.OldAddonDevices){
    this.plan_qty = Number(this.addonDevice)- this.selectValue;
   // this.defaultPrice();
   this.NoAdditionalDevice=this.plan_qty-this.OldAddonDevices;
   this.addonDevice=Number(this.OldAddonDevices) + Number(this.NoAdditionalDevice);
   //Cost of added Addon device=AddedDevices * No of remaing day * Cost of one day price of idevices.
   this.AdditionalDeviceCost = Number(this.OneDeviceCost) * Number(this.CheckRemaingDays);
   this.AdditionalDeviceCost=this.AdditionalDeviceCost.toFixed(2);

   //this.totalDevice = this.addonDevice * this.secondSubscriptionPrice
   if(this.NoAdditionalDevice!=0){
   this.NoteValue= " Note: You have added "+ this.NoAdditionalDevice +" additional add-on devices for "+ this.CheckRemaingDays +" days, and you have to pay $"+ this.AdditionalDeviceCost + " per additional add-on device to continue with existing plan.";
   }
   else{
    if( this.selectValue==1){
      this.NoteValue="*Note: Each additional device will adjust 1 with (+/-) button.";
    }
    else{
      this.NoteValue="*Note: Each additional device will adjust 5 with (+/-) button.";
    }

   }
   this.totalDevice= Number(this.totalDevice) - Number(this.AdditionalDeviceCost);

   this.totalDevice=Number(this.totalDevice).toFixed(2);
   this.totalSubscription1 =  Number(this.totalSubscription ) +  this.addonDevice;
   this.finalPrice= Number(this.finalPrice)- Number(this.AdditionalDeviceCost)
   this.finalPrice=Number(this.finalPrice).toFixed(2)
   }
   else
   { alert(" You cannot decrease the already existing plan add-on devices.")

   }
  }

//this function is used to  send  selected data in payament page.

registerClick(){
  debugger
     if(this.btnRegisterText=='Continue'){
      if(this.addonDevice<=this.OldAddonDevices)
      {
         alert("You have not added add-on devices.")
         this.ngxService.start();
          //this.route.navigate(['subscriptiondetails']);
          this.route.navigate(['addtionaladdon']); //todo20Oct23
          this.ngxService.stop();
      }
     else{
      var checkAddonCost=(Number(this.AdditionalDeviceCost)* (Number(this.NoAdditionalDevice)/this.selectValue));
      if(Number(this.remainingAmount>0))
        {
        if(Number(this.remainingAmount) >= checkAddonCost)
        {this.AdditionalDeviceCost=checkAddonCost
          this.balanceAmount12=Math.abs(checkAddonCost-this.remainingAmount);  
          this.balanceAmount12=this.balanceAmount12.toFixed(2).toString();
           //this.AdditionalDeviceCost="0.00";
           alert("Your subscription amount has been adjusted with your remaining amount. The balance amount is $"+ this.balanceAmount12)
           
        }
        else{
   
          this.AdditionalDeviceCost= checkAddonCost -Number(this.remainingAmount);
          //this.AdditionalDeviceCost=this.totalDevice;
         this.remainingAmount="0.00";
         this.balanceAmount12=this.remainingAmount="0.00"
         //alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.balanceAmount12)
      
        }
      }
      else{
        this.AdditionalDeviceCost= checkAddonCost -Number(this.remainingAmount);
          //this.AdditionalDeviceCost=this.totalDevice;
         this.remainingAmount="0.00";
         this.balanceAmount12=this.remainingAmount="0.00"

      }
      
    //else{
       this.remainingAmount=this.remainingAmount;  
      this.registerUserModel.NumberOfSubscriber = this.plan_qty;
      this.registerUserModel.Price              = this.finalPrice;

      //to ckeck the plan
       debugger
      
       this.servicedata.updateApprovalMessage(this.totalSubscription1)
       this.servicedata.sendFinaltotalSelectedPrice(this.finalPrice)
       this.servicedata.sendAddonDeviceOnPaymentUI(this.addonDevice)
       this.servicedata.sendAddonPriceOnPaymentUI(this.totalDevice)
       this.servicedata.sendUserPlanOnPaymentUI(this.userPlan)
       this.servicedata.sendPlanValidity(this.ValidityofSubscription) 
       //to send the number and cost of Addition device in payment page
       this.servicedata.sendAdditionalAddonDeviceUi(this.NoAdditionalDevice)
       this.servicedata.sendAdditionalAddonDevicePriceUi(this.AdditionalDeviceCost)
       this.servicedata.sendRemaingAmountofOldPlan(this.remainingAmount)
       this.servicedata.sendBalanceRemaingAmountofOldPlan(this.balanceAmount12)
       const TempplanValidity= new Date(this.ValidityofSubscription);
       //const now= new Date();
       //const currentdate=new Date(now);
       if((this.registeredEamiId=="N/A")||(this.finalPrice=="NaN")){
          alert("Server error, please login again!")
         this.ngxService.start();
         this.route.navigate(['login']);
         this.ngxService.stop();
         }  
       else{
          this.ngxService.start();
          this.route.navigate(['payment']);
          this.ngxService.stop();
          }
          
         }
        }
         else{
        var model:any;
     //this.registerUser(model);
    }
  }
  /// this function is used to select the data from  drop down according to user selection.
 selectedLevel:any 
  
     data = [
     
      {id: 0, name: "Bronze Subscription"},
      {id: 1, name: "Silver Subscription"},
      {id: 2, name: "Gold Subscription"},
      {id: 3, name: "New Subscription"}
     ];
     
     selected(){
      debugger
      if( this.selectedLevel==this.SetAgaintheExistingPlan)
       {
        window.location.reload();
        this.ngxService.start();
       
        this.route.navigate(['addtionaladdon']);
      
        this.ngxService.stop();
       }
       else if(this.selectedLevel.name=="New Subscription")
         {
          alert("Please open CYA Release Form iPad app and sign up to create a new subscription.")
          this.route.navigate(['login']);
         }
       else{
          this.ngxService.start();
          this.route.navigate(['subscriptiondetails']);
          this.ngxService.stop();
       }
     }
  
}






