<ngx-ui-loader
 fgsColor="#ff0000"
 pbColor="#ff0000" 
 text=" Please wait..." 
 textColor="#ff0000" >
</ngx-ui-loader>   
    <router-outlet></router-outlet>

 

