import { Component,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Registerrequest } from 'src/app/models/registerrequest';
import { AccountService } from 'src/app/_services/account.service';
import { NgForm } from '@angular/forms';
import { FreeTrialRegisterRequest, RegisterRequestModel } from 'src/app/models/loginrequest';
declare var $:any;


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  showModal: boolean = false;
  emailSendTo: any;
  model:RegisterRequestModel = new RegisterRequestModel();
  element: HTMLElement;
  registerUserModel:RegisterRequestModel;
  
  constructor(private route:Router, private service:AccountService) {

   

   }

  ngOnInit(): void {
    this.service.model.subscribe(data => {
      this.registerUserModel = data;
    });

  }

  fakeClick(){
    this.element = document.getElementById('createRegistraion') as HTMLElement;
    this.element.click();
  }


  register(){
    console.log(this.model);
    this.registerClickEvent(this.model.EmailId);
    }

  registerClickEvent(email:string){
   
    email=this.model.EmailId;

    var response = this.service.checkUserExist(email).subscribe(res=>{
      console.log("data",res);

      if(!res.Data){

       this.showModal = true;
       this.emailSendTo=email;

       var sendMailresponse = this.service.sendOTPForEmailVerification(email).subscribe(res1=>{
        
         
        },(err:HttpErrorResponse)=>{
        alert("This is the error response for the otp verification");
        })

      }
      else{
        this.showModal = false;
        alert("User already exist. Try Login!");
       
      }
      //todo: vivekv check if user exist then send the OTP to the user after getting the response

    },(err:HttpErrorResponse)=>{
      alert("This alert is for the error");
    })
  }



  redirectToLogin(){
    this.route.navigate(['login']);
  }

  resendOTP(email:string){
    var sendMailresponse = this.service.sendOTPForEmailVerification(email).subscribe(res1=>{
         
    },(err:HttpErrorResponse)=>{
    alert("this is the error response for the otp verification");
    })
        
  }
   
  verifyOTP(email:string,otp:any){

    var sendMailresponse = this.service.verifyOtp(email,otp).subscribe(res1=>{
       
      console.log("data",res1);

      if(res1.ResponseCode==200){
        // if(res1.ResponseMSG  =='Invalid OTP'){
        //   //invalid otp entered
        // }
        // else{
          this.fakeClick();
          
          this.registerUserModel.FirstName        = this.model.FirstName;
         // this.registerUserModel.LastName         = this.model.LastName;
          this.registerUserModel.EmailId          = this.model.EmailId;
         // this.registerUserModel.ContactNumber    = this.model.ContactNumber;
         // this.registerUserModel.Mailingaddress   = this.model.Mailingaddress;
          //this.registerUserModel.ShopCompanyName  = this.model.ShopCompanyName;
          //this.registerUserModel.Password         = this.model.Password;
          //this.registerUserModel.IsTermCondition  = true;

          this.route.navigate(['subscriptiondetails']);
        // }
      }
      else if(res1.ResponseCode==203){
        alert("OTP Expired");
      }
      else if(res1.ResponseCode==400){
        alert("Unauthorized User");
      }
      else{
          alert("Internal Server Error");
      }
         
    },(err:HttpErrorResponse)=>{
    alert("this is the error response for the otp verification");
    })
  }
}
