import { Component, OnInit } from '@angular/core';
// import {} from '../../../assets/js';
declare var jQuery: any;

@Component({
  selector: 'app-clientdashboard',
  templateUrl: './clientdashboard.component.html',
  styleUrls: ['./clientdashboard.component.css']
})
export class ClientdashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    this.menuClick();

  }


  private menuClick():void{
    (function ($) {
     
      $(document).ready(function(){
        $("#hide").click(function(){
          $("#sidebar-section").hide();
        });
        $("#show").click(function(){
          $("#sidebar-section").show();
        });
      });
    })(jQuery);
  }


}
