
    <div class="container-fluid signup">
        <div class="row">
            <div class="col-md-6 pr-0 text-center">
                <div class="contentWrapper">
                    <img src="assets/img/logo.png">
                    <p class="mt-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br>
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                    <h6 class="mt-4" >Already have an account?</h6>
                    <button class="btn btn-outlined" (click)="redirectToLogin()">Log In</button>
                </div>
            </div>
            <div class="col-md-6 loginInput">
              <form #registerForm="ngForm"   (ngSubmit)="register()">
                <div class="contentWrapper">
                    <h2>Create Account</h2>
                    <p class="mb-5"></p>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>First name</label>
                                    <div class="formInput position-relative">
                                        <input type="text" class="form-control" ngModel #fName="ngModel" placeholder="Enter First Name"  name="fName" [(ngModel)]="model.FirstName" id="fName" required>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="position-absolute" width="27"
                                            height="25.468" viewBox="0 0 27 25.468">
                                            <g id="male-user-shadow" transform="translate(0 -7.045)">
                                                <g id="Group_3" data-name="Group 3" transform="translate(0 7.045)">
                                                    <path id="Path_81" data-name="Path 81"
                                                        d="M1.082,147.549H25.918a1.125,1.125,0,0,0,.849-.371.915.915,0,0,0,.226-.731A13.662,13.662,0,0,0,19.8,136.113a8.9,8.9,0,0,1-12.6,0A13.66,13.66,0,0,0,.008,146.447a.917.917,0,0,0,.226.731A1.125,1.125,0,0,0,1.082,147.549Z"
                                                        transform="translate(0 -122.081)" fill="#e12614"></path>
                                                    <path id="Path_82" data-name="Path 82"
                                                        d="M55.194,20.1c.145.152.3.3.453.437a7.685,7.685,0,0,0,10.287,0c.157-.14.308-.285.453-.437s.285-.307.417-.471a7.731,7.731,0,1,0-12.027,0C54.909,19.789,55.049,19.943,55.194,20.1Z"
                                                        transform="translate(-47.291 -7.045)" fill="#e12614"></path>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                    <small style="color:red " *ngIf="fName.errors && (fName.dirty || fName.touched)">  
                                        <p *ngIf="fName.errors.required">
                                            First name is required
                                           </p>
                                    </small>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <div class="formInput position-relative">
                                        <input type="text" class="form-control" ngModel #lName="ngModel"  placeholder="Enter Last Name"  name="lname" [(ngModel)]="model.LastName" id="lname" required>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="position-absolute" width="27"
                                            height="25.468" viewBox="0 0 27 25.468">
                                            <g id="male-user-shadow" transform="translate(0 -7.045)">
                                                <g id="Group_3" data-name="Group 3" transform="translate(0 7.045)">
                                                    <path id="Path_81" data-name="Path 81"
                                                        d="M1.082,147.549H25.918a1.125,1.125,0,0,0,.849-.371.915.915,0,0,0,.226-.731A13.662,13.662,0,0,0,19.8,136.113a8.9,8.9,0,0,1-12.6,0A13.66,13.66,0,0,0,.008,146.447a.917.917,0,0,0,.226.731A1.125,1.125,0,0,0,1.082,147.549Z"
                                                        transform="translate(0 -122.081)" fill="#e12614"></path>
                                                    <path id="Path_82" data-name="Path 82"
                                                        d="M55.194,20.1c.145.152.3.3.453.437a7.685,7.685,0,0,0,10.287,0c.157-.14.308-.285.453-.437s.285-.307.417-.471a7.731,7.731,0,1,0-12.027,0C54.909,19.789,55.049,19.943,55.194,20.1Z"
                                                        transform="translate(-47.291 -7.045)" fill="#e12614"></path>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                    <small style="color:red " *ngIf="lName.errors && (lName.dirty || lName.touched)">  
                                        <p *ngIf="lName.errors.required">
                                            Last name is required
                                           </p>
                                    </small>
                                </div>
                            </div> -->
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>Email</label>
                                    <div class="formInput position-relative">
                                        <input type="email" class="form-control"  placeholder="Enter Email" 
                                         name="email"  id="email" ngModel #email="ngModel" [(ngModel)]="model.EmailId"  required>
                                        <svg class="position-absolute" xmlns="http://www.w3.org/2000/svg" width="29.25"
                                            height="20.25" viewBox="0 0 29.25 20.25">
                                            <g id="Icon_ionic-ios-mail" data-name="Icon ionic-ios-mail"
                                                transform="translate(-3.375 -7.875)">
                                                <path id="Path_596" data-name="Path 596"
                                                    d="M32.386,10.357,24.82,18.063a.136.136,0,0,0,0,.2L30.115,23.9a.912.912,0,0,1,0,1.294.917.917,0,0,1-1.294,0l-5.273-5.618a.144.144,0,0,0-.2,0l-1.287,1.308a5.661,5.661,0,0,1-4.036,1.7,5.775,5.775,0,0,1-4.12-1.751l-1.238-1.259a.144.144,0,0,0-.2,0L7.186,25.193a.917.917,0,0,1-1.294,0,.912.912,0,0,1,0-1.294l5.295-5.639a.15.15,0,0,0,0-.2L3.614,10.357a.139.139,0,0,0-.239.1v15.42a2.257,2.257,0,0,0,2.25,2.25h24.75a2.257,2.257,0,0,0,2.25-2.25V10.455A.141.141,0,0,0,32.386,10.357Z"
                                                    fill="#e12614" />
                                                <path id="Path_597" data-name="Path 597"
                                                    d="M18,20.749A3.823,3.823,0,0,0,20.749,19.6L31.781,8.367a2.21,2.21,0,0,0-1.392-.492H5.618a2.2,2.2,0,0,0-1.392.492L15.258,19.6A3.823,3.823,0,0,0,18,20.749Z"
                                                    fill="#e12614" />
                                            </g>
                                        </svg>
                                    </div>
                                    <small style="color:red " *ngIf="email.errors && (email.dirty || email.touched)">  
                                                         <p *ngIf="email.errors.required">
                                                     Email is required
                                                            </p>
                                    </small>

                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label>Contact Number</label>
                                    <div class="formInput position-relative">
                                        <input type="text" minlength="10"  ngModel #phone="ngModel"  maxlength="12" class="form-control" placeholder="Enter Contact Number"  name="phone" [(ngModel)]="model.ContactNumber" id="phonenumber" required>
                                        <svg class="position-absolute" style="width: 15px;" xmlns="http://www.w3.org/2000/svg" width="17.944" height="31.685" viewBox="0 0 17.944 31.685">
                                            <g id="Group_24" data-name="Group 24" transform="translate(-1477.556 -531)">
                                              <rect id="Rectangle_16" data-name="Rectangle 16" width="17" height="24" transform="translate(1478 534)" fill="#ff7d7d"/>
                                              <g id="smartphone" transform="translate(1470.686 531)">
                                                <path id="Path_588" data-name="Path 588" d="M22.507,0H9.175A2.306,2.306,0,0,0,6.87,2.309v27.07a2.306,2.306,0,0,0,2.305,2.306H22.507a2.308,2.308,0,0,0,2.307-2.306V2.309A2.31,2.31,0,0,0,22.507,0Zm.578,25.672H8.6V3.895H23.085ZM18.932,2.343H12.751V1.669h6.182v.674Zm2.645-.308a.591.591,0,1,1-.591-.59A.591.591,0,0,1,21.577,2.035Zm-2.922,27.19H13.026V27.493h5.629Z" fill="#e12614"/>
                                              </g>
                                            </g>
                                          </svg>
                                          

                                    </div>
                                    <small style="color:red " *ngIf="phone.errors && (phone.dirty || phone.touched)">  
                                        <p *ngIf="phone.errors.required">
                                            Contact Number is required
                                           </p>
                                    </small>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>Address</label>
                                    <div class="formInput position-relative">
                                        <input class="form-control" ngModel #mailingaddress="ngModel" placeholder="Enter Mailing Address"  name="mailingaddress" [(ngModel)]="model.Mailingaddress" id="mailingaddress" required>
                                        <svg class="position-absolute" xmlns="http://www.w3.org/2000/svg" width="23.369"
                                            height="34.398" viewBox="0 0 23.369 34.398">
                                            <g id="add-location-point" transform="translate(-5.515)">
                                                <g id="c28_geolocalization">
                                                    <path id="Path_595" data-name="Path 595"
                                                        d="M17.2,0A11.648,11.648,0,0,0,5.515,11.607c0,3.281,3.218,9.156,3.218,9.156L16.772,34.4l8.386-13.477s3.726-5.6,3.726-9.314A11.646,11.646,0,0,0,17.2,0Zm-.055,18a6.689,6.689,0,1,1,6.69-6.693A6.688,6.688,0,0,1,17.147,18Z"
                                                        fill="#e12614" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <small style="color:red " *ngIf="mailingaddress.errors && (mailingaddress.dirty || mailingaddress.touched)">  
                                        <p *ngIf="mailingaddress.errors.required">
                                            Address is required
                                           </p>
                                    </small>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label>Shop/Company Name</label>
                                    <div class="formInput position-relative">
                                        <input type="text"  ngModel #companyname="ngModel"  class="form-control" placeholder="Enter Company Address"  name="companyname" [(ngModel)]="model.ShopCompanyName" id="companyname" required >
                                        <svg class="position-absolute" xmlns="http://www.w3.org/2000/svg" width="27.32"
                                            height="23.905" viewBox="0 0 27.32 23.905">
                                            <path id="Icon_awesome-briefcase" data-name="Icon awesome-briefcase"
                                                d="M17.075,18.471a.854.854,0,0,1-.854.854H11.1a.854.854,0,0,1-.854-.854V15.91H0v7.684a2.626,2.626,0,0,0,2.561,2.561h22.2a2.626,2.626,0,0,0,2.561-2.561V15.91H17.075Zm7.684-11.1H20.49V4.811A2.626,2.626,0,0,0,17.929,2.25H9.391A2.626,2.626,0,0,0,6.83,4.811V7.373H2.561A2.626,2.626,0,0,0,0,9.934V14.2H27.32V9.934A2.626,2.626,0,0,0,24.759,7.373Zm-7.684,0h-6.83V5.665h6.83Z"
                                                transform="translate(0 -2.25)" fill="#e12614" />
                                        </svg>
                                    </div>
                                    <small style="color:red " *ngIf="companyname.errors && (companyname.dirty || companyname.touched)">  
                                        <p *ngIf="companyname.errors.required">
                                            Shop/Company Name is required
                                           </p>
                                    </small>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label>Password</label>
                                    <div class="formInput position-relative">
                                        <input type="password"  ngModel #psw="ngModel"  class="form-control" placeholder="Enter Password"  name="psw" [(ngModel)]="model.Password" id="psw" required >
                                        <svg class="position-absolute" xmlns="http://www.w3.org/2000/svg" width="25.234"
                                            height="34" viewBox="0 0 25.234 34">
                                            <g id="lock" transform="translate(-66)">
                                                <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.5" cy="1.5" r="1.5"
                                                    transform="translate(77.542 20)" fill="#e12614" />
                                                <path id="Path_83" data-name="Path 83"
                                                    d="M88.113,13.016V9.5a9.5,9.5,0,0,0-18.992,0v3.52H66v8.367a12.617,12.617,0,1,0,25.234,0V13.016Zm-8.5,11.814v3.106H77.621V24.829a3.652,3.652,0,1,1,1.992,0Zm6.508-11.814H71.113V9.5a7.5,7.5,0,1,1,15.008,0Z"
                                                    fill="#e12614" />
                                            </g>
                                        </svg>
                                    </div>
                                    <small style="color:red " *ngIf="psw.errors && (psw.dirty || psw.touched)">  
                                        <p *ngIf="psw.errors.required">
                                            Password is required
                                           </p>
                                    </small>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label>Confirm Password</label>
                                    <div class="formInput position-relative">
                                        <input type="password" ngModel #pswconfirm="ngModel"  class="form-control" placeholder="Enter Confirm Password"  name="pswconfirm" [(ngModel)]="model.Password" id="psw-confirm" required >
                                        <svg class="position-absolute" xmlns="http://www.w3.org/2000/svg" width="25.234"
                                            height="34" viewBox="0 0 25.234 34">
                                            <g id="lock" transform="translate(-66)">
                                                <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.5" cy="1.5" r="1.5"
                                                    transform="translate(77.542 20)" fill="#e12614" />
                                                <path id="Path_83" data-name="Path 83"
                                                    d="M88.113,13.016V9.5a9.5,9.5,0,0,0-18.992,0v3.52H66v8.367a12.617,12.617,0,1,0,25.234,0V13.016Zm-8.5,11.814v3.106H77.621V24.829a3.652,3.652,0,1,1,1.992,0Zm6.508-11.814H71.113V9.5a7.5,7.5,0,1,1,15.008,0Z"
                                                    fill="#e12614" />
                                            </g>
                                        </svg>
                                    </div>
                                    <small style="color:red " *ngIf="pswconfirm.errors && (pswconfirm.dirty || pswconfirm.touched)">  
                                        <p *ngIf="pswconfirm.errors.required">
                                            Confirm Password is required
                                           </p>
                                    </small>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="container d-flex align-items-baseline">
                        <input type="checkbox"  ngModel #terms="ngModel" name="terms"  checked="checked" required>
                        <p class="ml-2 remember">By creating an account you agree to our <a href="/terms-and-condition">Terms & Privacy</a>.</p><br>
                       
                    </div>
                    <small style="color:red " *ngIf="terms.errors && (terms.dirty || terms.touched)">  
                        <p *ngIf="terms.errors.required">
                            Please check terms and condition before proceeding.
                           </p>
                    </small>
                    <button class="btn btnSubmit"  type="submit" data-toggle="modal" data-target="#createRegistraion" [disabled]="!registerForm.form.valid" >Client Registration</button>
                </div>
              </form>
            </div>
        </div>
    </div>


<!-- Modal -->
  <div class="modal fade" id="createRegistraion"  tabindex="-1" role="dialog" aria-labelledby="createRegistraion" aria-hidden="true"
      [ngStyle]="{'display':showModal?'block':'none'}" >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Email Verification</h5>
          <button type="button" class="close icon" data-dismiss="modal"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-6"> <label>Email sent verification:</label> </div>
                <div class="col-sm-6">
                    <div>{{emailSendTo}}</div>
                </div>
                <div class="col-sm-12 align-content-button">
                    <input type="text" placeholder="Enter OTP"  name="Otp" id="Otp" #Otp  >
                    <p class="paragraph">Did't recive security code? <a (click)="resendOTP(model.EmailId)">Resend</a> </p>
                    <button type="button" class="btn btn-primary Verify" (click)="verifyOTP(model.EmailId,Otp.value)">Verify & Proceed</button>
                </div>
            </div>

        </div>
      </div>
    </div>
  



</div>
