import { Component, OnInit } from '@angular/core';
import { Loginrequest } from '../../models/loginrequest';
import { HttpErrorResponse } from '@angular/common/http';
import {NavigationStart, Router } from '@angular/router';
import { AccountService } from 'src/app/_services/account.service';
import { TransferdataService } from "src/app/transferdata.service";
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';

export let browserRefresh = false;
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerdEmail:string;
  sendemailId: string;
    
  
  constructor( private ngxService: NgxUiLoaderService, private service: AccountService,private route:Router, private servicedata:TransferdataService , private location: Location) {

   }
  ngOnInit(): void {
    this.location.subscribe(() =>{
      history.pushState(null, this.location.path());
     this.sendemailId='N/A'
    });
  
    this.servicedata.selectedPlanPricermessage.subscribe(msg => this.registerdEmail = msg);


  }
// this function is used to login user.
   LoginClickEvent(email:string,password:string){
    this.sendemailId= email;
     var data:Loginrequest={
      EmailId:email,
      Password:password
      
    };
    this.ngxService.start();
    var response = this.service.loginService(data).subscribe(res=>{

      let result = JSON.stringify(res);
      debugger
      console.log('>>>>>>>>>>>>>>',res.Data)

      if(res.ResponseCode==200){
        localStorage.setItem('token',res.Data.Token);

        localStorage.setItem('fName',res.Data.fName);
        localStorage.setItem('lName',res.Data.lName);
       // alert("Authorized user");
        if(res.Data.RoleId==2){
         // this.route.navigate(['clientdashboard']);
         this.servicedata.sendRegisteredEmailOnPaymentUI(data.EmailId)
         this.servicedata.sendLoginUserIdOnPaymentUI(res.Data.UserId)
         this.servicedata.sendLoginUserNameOnUI(res.Data.FirstName)
         this.servicedata.sendTotalDeviceCountInfo(res.Data.DeviceCount)
         this.route.navigate(['subscriptiondetails'])
        }
        else if(res.Data.RoleId==1){
          this.route.navigate(['superadmindashboard']);
        }
        else{
          //no action required
          this.route.navigate(['**']);
        }
      }
      else if(res.ResponseCode==400){
        alert("Unauthorized user");
      } 
      else{
        alert("Internal Server Error");
      }

    },(err:HttpErrorResponse)=>{
      alert("Invalid Credential.");
    })
    this.ngxService.stop();
  }

  public redirectToRegister():void{
    this.route.navigate(['register']);
  }

}
