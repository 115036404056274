import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {  HttpClientModule } from '@angular/common/http'
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AdditionaladdonComponent } from './components/additionaladdon/additionaladdon.component';
import { TermsandconditionComponent } from './components/termsandcondition/termsandcondition.component';
import { ForgetpasswordComponent } from './components/forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SuperadmindashboardComponent } from './components/superadmindashboard/superadmindashboard.component';
import { ClientdashboardComponent } from './components/clientdashboard/clientdashboard.component';
import { PaymentComponent } from './components/payment/payment.component';
import { SubscriptiondetailsComponent } from './components/subscriptiondetails/subscriptiondetails.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";


@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    LoginComponent,
    RegisterComponent,
    AdditionaladdonComponent,
    TermsandconditionComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    SuperadmindashboardComponent,
    ClientdashboardComponent,
    PaymentComponent,
    SubscriptiondetailsComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbAlertModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({showForeground:true,}),
  ],

  providers: [{provide:LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
