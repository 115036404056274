import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TransferdataService {
  // to send total number of device price on payment UI
  private approvalStageMessage = new BehaviorSubject('Basic device is required!');
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();
 
  // to send calculated price on payment UI
  private selectPlanTotalPriceMessage= new BehaviorSubject('N/A');
  selectedPlanPricermessage= this.selectPlanTotalPriceMessage.asObservable();

   // to send Registered Email on payment UI
   private sendEmailIdAsMessage= new BehaviorSubject('N/A');
   sendRegisteredEmailIdAsMessage= this.sendEmailIdAsMessage.asObservable();

   // to send Registered Plan Validity on payment UI
   private sendValidityofPlanAsMessage= new BehaviorSubject('N/A');
   sendValidityAsMessage= this.sendValidityofPlanAsMessage.asObservable();

    // to send Registered UseId on payment UI
    private sendUserIdAsMessage= new BehaviorSubject('N/A');
    sendLoginUserIdAsMessage= this.sendUserIdAsMessage.asObservable();

    // to send Registered UserName on  UI
    private sendUserNameAsMessage= new BehaviorSubject('N/A');
    sendLoginUserNameAsMessage= this.sendUserNameAsMessage.asObservable();

    // to send AddonDevice on payment UI
    private sendUserAddonDeviceMessage= new BehaviorSubject('N/A');
    sendAddonDeviceMessage= this.sendUserAddonDeviceMessage.asObservable();

    // to send  AddonPrice on payment UI
    private sendUserAddonPriceMessage= new BehaviorSubject('N/A');
    sendAddonPriceMessage= this.sendUserAddonPriceMessage.asObservable();

    // to send Plan Name on payment UI
    private sendPlanNameAsMessage= new BehaviorSubject('N/A');
    sendPlanNameMessage= this.sendPlanNameAsMessage.asObservable();
    // to send Additional Addon Devices on payment UI
    private sendAdditionalAddonDevices= new BehaviorSubject('N/A');
    AdditionalAddonDevices1= this.sendAdditionalAddonDevices.asObservable();

    // to send Additional Addon Devices  Price on payment UI
    private sendAdditionalAddonDevicesPrice= new BehaviorSubject('N/A');
    AdditionalAddonDevicesPrice1= this.sendAdditionalAddonDevicesPrice.asObservable();

    // to send Remaining Amount  on payment UI
    private sendRemainingAmountofoldSubscription= new BehaviorSubject('N/A');
    remainingAmountofoldSubscription= this.sendRemainingAmountofoldSubscription.asObservable();

    // to send Adjusted Amount ofCurrent Subscription  on payment UI
    private sendAdjustedAmountofCurrentSubscription= new BehaviorSubject('N/A');
    adjustedAmountofCurrentSubscriptionSubscription= this.sendAdjustedAmountofCurrentSubscription.asObservable();

    private sendBalanceOfRemainingAmountofoldSubscription= new BehaviorSubject('N/A');
    balanceOfRemainingAmountofoldSubscription= this.sendBalanceOfRemainingAmountofoldSubscription.asObservable();
    //todo22Nov23 for DeviceCount
    private sendDeviceCountInfo= new BehaviorSubject('N/A');
    totalNumberofDeviceCountInfo= this.sendDeviceCountInfo.asObservable();
 

 

  constructor() { }
  //todo 22Nov23 for DeviceCount
  sendTotalDeviceCountInfo(DeviceCount: string){
    this.sendDeviceCountInfo.next(DeviceCount)
    }
  // to send Remaing balanceAmount  on payment UI
 sendBalanceRemaingAmountofOldPlan(balanceAmount12: string){
  this.sendBalanceOfRemainingAmountofoldSubscription.next(balanceAmount12)
  }

  // to send Remaing Amount  on payment UI
   sendRemaingAmountofOldPlan(remainingAmount: string){
    this.sendRemainingAmountofoldSubscription.next(remainingAmount)
    }
    // to send Adjusted Amount  on payment UI
   sendAdjustedAmountofCurrentPlan(finalPrice1: string){
    this.sendAdjustedAmountofCurrentSubscription.next(finalPrice1)
   }


  updateApprovalMessage(message: string) {
    this.approvalStageMessage.next(message)
    }
    
   // to send calculated price on payment UI
    sendPlanValidity(planValidity: string){
    this.sendValidityofPlanAsMessage.next(planValidity)
   }


    // to send calculated price on payment UI
    sendFinaltotalSelectedPrice(finalcostfromSubsUI: string){
      this.selectPlanTotalPriceMessage.next(finalcostfromSubsUI)
    }
      // to send email from  login  Ui

      sendRegisteredEmailOnPaymentUI(registerdEmail: string){
        this.sendEmailIdAsMessage.next(registerdEmail)
    }
    // to send userid from  login  Ui

    sendLoginUserIdOnPaymentUI(loginUSerId: string){
      this.sendUserIdAsMessage.next(loginUSerId)
  }
   // to send userName from  login  Ui

   sendLoginUserNameOnUI(loginUSerName: string){
    this.sendUserNameAsMessage.next(loginUSerName)
}

  // to send AddonDevice from  login  Ui

  sendAddonDeviceOnPaymentUI(addonDevice: string){
    this.sendUserAddonDeviceMessage.next(addonDevice)
}

// to send AddonDevice from  login  Ui

sendAddonPriceOnPaymentUI(addonPrice: string){
  this.sendUserAddonPriceMessage.next(addonPrice)
}


// to send Additional AddonDevice from  Pagenotfound  Ui

sendAdditionalAddonDeviceUi(additionlAddonDevice: string){
  this.sendAdditionalAddonDevices.next(additionlAddonDevice)
}

// to send  Additional AddonDevice Price from  login  Ui

sendAdditionalAddonDevicePriceUi(additionalDevicePrice: string){
  this.sendAdditionalAddonDevicesPrice.next(additionalDevicePrice)
}

// to send  Additional AddonDevice Price from  login  Ui

sendUserPlanOnPaymentUI(userPlane: string){
  this.sendPlanNameAsMessage.next(userPlane)
}


} 
