// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:'https://subscriptionsapis.cyareleaseforms.com/',//for production

 // baseUrl:'https://subscriptionsstagingapis.cyareleaseforms.com/',//for staging
  loginApi:'MultiClient/UserLogin',
  sendOtpApi:'MultiClient/SendEmailForOTP?email=',
  verifyOtpApi:'MultiClient/VerifyOTP',
  forgetPasswordOtpApi:'MultiClient/SendForgotPasswordOTP?email=',
  isClientExistApi:'MultiClient/IsClientExist?emailId=',
  getUserSubscriptionPrice:'MultiClient/GetSubscriptionPlanDetail',
  getSubscriptionPrice:'SuperAdmin/GetUpdatedSubscriptionDetail',

  //createMultiClientApi:'MultiClient/CreateMultiClientUser',
  createMultiClientApi:'MultiClient/RenewSubscription',
  createStripeCustomerApi:'MultiClient/CreateStripeCustomer',
  authorisepaymentApi:'https://api.authorize.net/xml/v1/request.api', //for production
 
 //authorisepaymentApi:'https://apitest.authorize.net/xml/v1/request.api',//for staging
 // constant file from the 
 SilverSubscription:'Silver Subscription'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
