
    
<!DOCTYPE html>
<html lang="en">

<head>
    <title>Homepage</title>
    <base href="/login">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
</head>

<body>
    <div class="container-fluid login">
       
          <!-- <h6 style="text-align: right; color: white;" ><a style="color: white;" >Logout</a>
        </h6> -->
         
        <div class="row">
           
            <div class="col-md-6 pr-0 text-center">
                <div class="contentWrapper">
                    <img src="assets/img/logo.png">
                                          
                    <!-- <h6 class="mt-4">Don't have an account?</h6> -->
                    <!-- <button class="btn btn-outlined" (click)="redirectToRegister()">Register New Account</button> -->
                </div>
            </div>
            <div class="col-md-6 loginInput">
                <form>
                 <div></div>   
                <div class="contentWrraper">
                    
                    <h2>LOGIN</h2>
                    <p class="mb-5">Enter your email and password to get access
                        to your account.</p>
                    <div class="form-group mb-4">
                        <label>Email</label>
                          <div class="formInput position-relative" >
                            <input type="text" class="form-control" ngModel #Emailid="ngModel"  placeholder="Enter email" name="uname"  #Email required/>

                            <!-- <input type="text" class="form-control" placeholder="Enter Email" name="uname" id="txtEmail" #Email required> -->
                              <svg xmlns="http://www.w3.org/2000/svg" class="position-absolute" width="27" height="25.468"
                                viewBox="-4 0 35 25.468">
                                <g id="male-user-shadow" transform="translate(0 -7.045)">
                                    <g id="Group_3" data-name="Group 3" transform="translate(0 7.045)">
                                        <path id="Path_81" data-name="Path 81"
                                            d="M1.082,147.549H25.918a1.125,1.125,0,0,0,.849-.371.915.915,0,0,0,.226-.731A13.662,13.662,0,0,0,19.8,136.113a8.9,8.9,0,0,1-12.6,0A13.66,13.66,0,0,0,.008,146.447a.917.917,0,0,0,.226.731A1.125,1.125,0,0,0,1.082,147.549Z"
                                            transform="translate(0 -122.081)" fill="#e12614" />
                                         <path id="Path_82" data-name="Path 82"
                                            d="M55.194,20.1c.145.152.3.3.453.437a7.685,7.685,0,0,0,10.287,0c.157-.14.308-.285.453-.437s.285-.307.417-.471a7.731,7.731,0,1,0-12.027,0C54.909,19.789,55.049,19.943,55.194,20.1Z"
                                         transform="translate(-47.291 -7.045)" fill="#e12614" />
                                    </g>
                                </g>
                            </svg>
                           </div>
                        <div>
                            <small style="color:red; padding-top: 35px; " *ngIf="Emailid.errors && (Emailid.dirty || Emailid.touched)">  
                                <p *ngIf="Emailid.errors.required">
                                    Email is required
                                   </p>
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        
                        <div class="formInput position-relative">
                            <input type="password" class="form-control" ngModel #password="ngModel"  placeholder="Enter password" name="psw"  #Password required>
                            <!-- <input type="password" class="form-control" placeholder="Enter Password" name="psw" id="txt"  required> -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="position-absolute" width="25.234" height="34"
                                viewBox="-4 0 35.234 34">
                                <g id="lock" transform="translate(-66)">
                                     <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.5" cy="1.5" r="1.5"
                                        transform="translate(77.542 20)" fill="#e12614" />
                                     <path id="Path_83" data-name="Path 83"
                                        d="M88.113,13.016V9.5a9.5,9.5,0,0,0-18.992,0v3.52H66v8.367a12.617,12.617,0,1,0,25.234,0V13.016Zm-8.5,11.814v3.106H77.621V24.829a3.652,3.652,0,1,1,1.992,0Zm6.508-11.814H71.113V9.5a7.5,7.5,0,1,1,15.008,0Z"
                                        fill="#e12614" />
                                </g>
                            </svg>
                           

                          </div>
                          <div>
                            <small style="color:red; padding-top: 35px;"  *ngIf="password.errors && (password.dirty || password.touched)">  
                                <p *ngIf="password.errors.required">
                                    Password is required
                                   </p>
                            </small>
                        </div>
                    </div>
                    <button class="btn btnSubmit" (click)="LoginClickEvent(Email.value,Password.value)">Login</button>
                    <!-- <p class="text-right forget remember">Forgot your password? Please <a href="/forgetpassword"> Click Here</a></p> -->
                    </div>
            </form>
            </div>
        </div>
    </div>

</body>

</html> 