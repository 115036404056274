<html lang="en">
    <head>
        <title>Payment</title>
        <base href="/payment">
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
    </head>
    <body>
        <div class="conatiner">
          <!-- <h6 style="text-align: right; background-color: black;" ><a style="color: white; padding-right: 35px; padding-top: 15;" href="https://subscriptions.cyareleaseforms.com" >Logout</a> -->
            <!-- <h6 style="text-align: right; background-color: black;" ><a style="color: white; padding-right: 35px; padding-top: 15;" href="https://subscriptionsstaging.cyareleaseforms.com/" >Logout</a>    -->
            <!-- <h6 style="text-align: right; background-color: black;" ><a style="color: white; padding-right: 35px; padding-top: 15;" href="#login" >Logout</a> -->
            
               
                    <div class="row" style="top: 40px;">
                         <div class="col-md-12">
                             
                            <div class="col-sm-12 text-right"  style="background-color: black;box-shadow: 0cap;box-sizing: 10px; padding-bottom: 5px;">
                                <button class="btn btn-primary"  style="background-color: black;border-color: black; text-decoration-style: bold;" type="submit"
                                    (click)="logOutClick()">{{btnLogOut}}</button>
                            </div>
                         </div>  
                   
                                     
                 </div>
              
<script src="https://js.stripe.com/v3/"></script>



    <div class="select-plan payment-method">
        <div class="group-grid ">
             
            <div class="grid-1">
                <div class="plan-card" style="background-color:  rgba(0,0,0,.75)"  >
                    <div class="plan-card-header">
                      Subscription Details
                    </div>
                    <div class="plan-card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <label class="col-6">{{ValidityofPlan}}</label>
                                    <label class="col-6">{{Planvalidity | date:'MM-dd-yyyy'}}</label>
                                    <label class="col-6">Email</label>
                                    <label class="col-6">{{registerdEmail}}</label>
                                    <label class="col-6">Plan Name</label>
                                    <label class="col-6">{{userPlane}}</label>
                                    <label class="col-6">Number of Devices</label>
                                    <label class="col-6">{{message}}</label>                                                                     
                                    <label class="col-6">Total Amount</label>
                                    <label class="col-6">$ {{finalcostfromSubsUI}}</label>
                                    
                                </div>
                                
                            </div>  
                        </div>
                    </div>
                    <div  *ngIf="edited"> 
                        <div class="plan-card-header">
                        Additional Add-on Devices Details
                     </div>
                    
                     <div class="plan-card-body"  >
                        <div class="row">
                           <div class="col-md-12">
                               <div class="row" style="top: 35px;" >
                                    <label class="col-6">Additional Add-on Devices</label>
                                   <label name="mylebel" class="col-6">{{noOfAdditionalAddonDevice}}</label>
                                   <label class="col-6"> Amount To Pay For Additional Add-on Devices </label>
                                   <label class="col-6" style="top: 25px;">$ {{additionalAddonDeviceCost}}</label>
                                   </div>
                              </div>  
                       </div>
                   </div>
                    </div>
                    
                    <div *ngIf="showHideRemainingAmount"> 
                         <div class="plan-card-header" >
                        Remaining and Current Amount. 
                       </div>
                       <div class="plan-card-body" >
                        <div class="row" style="top: 40px;">
                           <div class="col-md-12">
                               <div class="row" style="top: 45px;">
                                  <label class="col-6" >Remaining Amount to be adjustable with new plan</label>
                                   <label name="mylebel" class="col-6" > $ {{remainingAmount}}</label>
                               </div>
                               <div class="row" style="top: 25px;">
                                    <label class="col-6" > {{NoteValue12}}</label>                                 
                                   <label class="col-6" style="top: 25px;">$ {{finalPrice1}}</label>
                                   </div>
                              </div>  
                       </div>
                       </div>
                    </div>
                   
                    <div class="plan-card-body"  *ngIf="showHideProceed" >
                       <div class="row" style="top: 40px;">
                            <div class="col-md-12">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-primary" type="submit"
                                        (click)="registerClick()">{{btnProceedText}}</button>
                                </div>
                               
                            </div>  
                       </div>       
                                        
                    </div>
                </div>  
            </div>
            <div class="grid-1" *ngIf="showHideBillingPart" >
                <div class="plan-card" style="background-color:  rgba(0,0,0,.75)" >
                    <div class="plan-card-header">
                     Billing Information
                    </div>
                    <div class="plan-card-body">
                        <div class="row">
                            <div class="col-md-12">
                              
                                <div class="d-flex flex-wrap payment-icons">
                                    <img src="assets/img/visa.png" class="pr-3">
                                    <img src="assets/img/master.png" class="pr-3">
                                    <img src="assets/img/discover.png" class="pr-3">
                                    <img src="assets/img/american.png" class="pr-3">
                                </div>
                                <p style="color:white;">Enter Card Details</p>
                                <!-- <form #makePayment="ngForm"   (ngSubmit)="MakePaymentClick()"> -->
                               <form >
                                   
                                <div class="row card-details">

                                    <div class="col-12">
                                          <!-- <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout"> -->
                                        <div  style="display: flex" >
                                          <!-- <div style="width: 400px;"  id="card-info" #cardInfo></div> -->
                                         <input style="width:130x;" class="col-6" minlength="12" maxlength="16" type="text" ngModel #cardNumber="ngModel" class="form-control"  placeholder="Card Number" name="cNumber"  (keypress)="numberOnly($event)" />
                                         
                                          <input  style="width: 100px;"class="col-3"  type="text" ngModel #cardExpiry="ngModel"  class="form-control"  placeholder="MMYYYY" name="YYMM"  (keypress)="numberOnly($event)" />
                                          
                                          <input style="width: 75px;" class="col-3"  maxlength="4"type="text"  ngModel #CVV="ngModel" class="form-control"  placeholder="CVV " name="CVV" (keypress)="numberOnly($event)"/>
                                         
                                          <input  style="width: 80px;"class="col-3"  maxlength="5"type="text"  ngModel #Zip="ngModel" class="form-control"  placeholder=" Zip" name="Zip"  (keypress)="numberOnly($event)" />
                                          
                                          <!-- <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div> -->
                                        </div>
                                        <!-- <div  style="display: flex" >
                                            <p style="color: red " *ngIf="cardNumber.invalid && (cardNumber.dirty || cardNumber.touched)">
                                                Please enter a valid number with at most 16 digits.
                                              </p>
                                              <p style="color: red " *ngIf="cardExpir.invalid && (cardExpir.dirty || cardExpir.touched)">
                                                Please enter a valid date in MM/YYYY.
                                              </p>
                                              <p style="color: red " *ngIf="CVV.invalid && (CVV.dirty || CVV.touched)">
                                                Please enter a valid CVV number.
                                              </p>
                                              <p style="color: red " *ngIf="Zip.invalid && (Zip.dirty || Zip.touched)">
                                                Please enter 6 digit zip code.
                                              </p>
                                        </div> -->
                                     <!-- </form> -->
                                    </div>
                                  </div>
                                 <div class="row">
                                    <label class="col-sm-3">Full Name</label>
                                    <div class="col-sm-9">
                                        <input type="text"   class="form-control" ngModel #fullName="ngModel"  placeholder="Enter full name" name="fullName" (keyup)="onKeypressEvent($event)" (keypress)="onKeypressEvent($event)" required />
                                        <!-- <small style="color:red " *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">  
                                            <p *ngIf="fullName.errors.required">
                                                Full name is required
                                               </p>
                                        </small> -->
                                    </div>
                                   
                                    <label class="col-sm-3">City</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" ngModel #city="ngModel"  placeholder="Enter city" name="city" (keyup)="onKeypressEvent($event)" (keypress)="onKeypressEvent($event)" required />
                                        <!-- <small style="color:red " *ngIf="city.errors && (city.dirty || city.touched)">  
                                            <p *ngIf="city.errors.required">
                                                City name is required
                                               </p>
                                        </small> -->
                                    </div>
                                    
                                    <label class="col-sm-3">State</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" ngModel #state="ngModel"  placeholder="Enter state" name="state" (keyup)="onKeypressEvent($event)" (keypress)="onKeypressEvent($event)" required />
                                        <!-- <small style="color:red " *ngIf="state.errors && (state.dirty || state.touched)">  
                                            <p *ngIf="state.errors.required">
                                                State name is required
                                               </p>
                                        </small> -->
                                    </div>
                                  
                                    <label class="col-sm-3">Country</label>
                                    <div class="col-sm-9">
                                        <select class="form-control">
                                            <option>USA</option>
                                        </select>
                                    </div>
                                </div> 
                                <div class="row">
                                    <!-- <div class="col-md-12">
                                        <label>
                                         <input type="checkbox" [(ngModel)]="isChecked" />
                                        Do you want to go with recurring payment
                                      </label>
                                       <label>
                                        <input type="checkbox" [(ngModel)]="isChecked" />
                                        
                                        You have read <a href="#" (click)="handleLinkClick($event)"> Terms and Conditions</a>
                                      </label>
                                      
                                     </div> -->
                                     <div class="col-md-12 text-center" >
                                        <!-- <button style="background-color:red;color:white;width:110px; height:40px;" class="btn btnSubmit" (click)="MakePaymentClick()">Pay</button> -->
                                        <button style="background-color:red;color:white;width:110px; height:40px;" class="btn btnSubmit" (click)="MakePaymentClick(cardNumber.value,cardExpiry.value,CVV.value,Zip.value)">Pay</button>
                                     
                                    </div>
                                   
                                
                                </div>
                                </form>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </body>
</html>
      

