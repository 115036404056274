import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superadmindashboard',
  templateUrl: './superadmindashboard.component.html',
  styleUrls: ['./superadmindashboard.component.css']
})
export class SuperadmindashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
