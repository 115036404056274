import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientdashboardComponent } from './components/clientdashboard/clientdashboard.component';
import { ForgetpasswordComponent } from './components/forgetpassword/forgetpassword.component';
import { LoginComponent } from './components/login/login.component';
import { AdditionaladdonComponent } from './components/additionaladdon/additionaladdon.component';
import { PaymentComponent } from './components/payment/payment.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SubscriptiondetailsComponent } from './components/subscriptiondetails/subscriptiondetails.component';
import { SuperadmindashboardComponent } from './components/superadmindashboard/superadmindashboard.component';
import { TermsandconditionComponent } from './components/termsandcondition/termsandcondition.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

const routes: Routes = [
  {path:'',redirectTo:'/login',pathMatch:'full'},
 // {path:'login',component:WelcomeComponent},
  {path:'login',component:LoginComponent},
  {path:'register',component:RegisterComponent},
  {path:'terms-and-condition',component:TermsandconditionComponent},
  {path:'resetpassword',component:ResetpasswordComponent},
  {path:'forgetpassword',component:ForgetpasswordComponent},
  {path:'superadmindashboard',component:SuperadmindashboardComponent,children:[
    //todo :
  ]},
  {path:'clientdashboard',component:ClientdashboardComponent},
   {path:'payment',component:PaymentComponent},
   {path:'subscriptiondetails',component:SubscriptiondetailsComponent},
  {path:'additionaladdon' , component:AdditionaladdonComponent},//if url doesn't match then it redirect to the home or default no such page found error
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
