import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
import {NavigationStart, Router } from '@angular/router';
import { FreeTrialRegisterRequest, RegisterRequestModel } from 'src/app/models/loginrequest';
import { AccountService } from 'src/app/_services/account.service';
import { TransferdataService } from "src/app/transferdata.service";
//todo15
import { Subscription, BehaviorSubject } from 'rxjs';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as moment from 'moment';
import { checkServerIdentity } from 'tls';

export let browserRefresh = false;

@Component({
  selector: 'app-subscriptiondetails',
  templateUrl: './subscriptiondetails.component.html',
  styleUrls: ['./subscriptiondetails.component.css']
})
export class SubscriptiondetailsComponent implements OnInit {
  [x: string]: any;
  subscription: Subscription;
  isFreeTrial :boolean;
  isSubscription :boolean;
  freeTrialDaysdays:any ;
  firsSubscripptionPrice:any ;
  secondSubscriptionPrice:any ;
  plan_qty:number=0;
  addonDevice:any;
  finalPrice :any = 0;
  btnRegisterText : string = 'Continue';
  btnCencelText : string = 'Cancel';
  showHideCancel:boolean=false;
  showHideContinue:boolean=true;
  btnLogOut: string='Logout';

  DateShow:any
  totalDevice:any=0;
  totalSubscription:any
  gtotalSubscription1:any
  gfirsSubscripptionPrice:any ;
  gsecondSubscriptionPrice:any ;
  gtotalSubscription:any
  gtotalDevice:number=0;
  gDeviceCount:number=0;
  balanceAmount10:any;
  totalDeviceCount:any;//22Nov23
  tRemainingdate:any

//todo27-12-22
  
  registerUserModel:RegisterRequestModel;
  totalSubscription1: any;
  selectValue: any;
  silverBasePrice:any;
  silverAddonPrice:any;
  silverDevices:any
  silverDevices1: any;
  //todo7Dec23

  bronzeBasePrice:any;
  bronzeAddonPrice:any;
  bronzeDevices:any
  bronzeDevices1: any;

  UserbronzeBasePrice:any;
  UserbronzeAddonPrice:any;
  UserbronzeDevices:any
  UserbronzeDevices1: any;
//todorekha
 message:string="";
 approvalText:string;
 finalcostfromSubsUI:string;
  UsersilverBasePrice:any;
  UsersilverAddonPrice:any;
  UsersilverDevices:any;
  UsersilverDevices1:any;
  userPlan:any;
  //for using Addonaddedvalue
  noOfAdditionalAddonDevice: any;
  AdditionalDeviceCost:any;

  ///22sept2023  get a value of one day cost
  onedaycostofSilverbaseDevice:any;
  onedaycostofSilverAddonDevice:any;
  CurrentSilverSubscriptionRemainingDaysAmount:any
  NoteValueforCurrentremainingAmount:string;
  
  //forgold
  onedaycostofGoldbaseDevice:any;
  onedaycostofGOldAddonDevice:any
  CurrentGoldSubscriptionRemainingDaysAmount:any

  //Bronze dated 8Dec-23
  onedaycostofbronzebaseDevice:any;
  onedaycostofbronzeAddonDevice:any
  CurrentbronzeSubscriptionRemainingDaysAmount:any
  //todo15Jan24
  SilverExistingBaseDevive:any
  GoldExistingBaseDevive:any
  BronzeExistingBaseDevive:any

  userfirsSubscripptionPrice:any;
  usersecondSubscriptionPrice:any;
  usertotalSubscription:any;
  usertotalSubscription1:any;
 
  guserfirsSubscripptionPrice:any;
  gusersecondSubscriptionPrice:any;
  gusertotalSubscription:any;
  gusertotalSubscription1:any;
  OneGoldAddonPrice:any;
  NoteValue:string;
  SetAgaintheExistingPlan : string;
  ValidityofSubscription: any;
  todaysdate:any;
  CheckRemaingDays : any;
  ExistingPlanPrice :any;
  remainingAmount: any;
  RenewSubscription :boolean=false;
  ChangeExistingPlanSubscription :boolean=false;
  finalPrice1: any;
  selectedValue:any;
  myValue = 1;
  oldValue = 1 ;
  SubscriptionNoteValue: any;
  onedayCost:any;
  setRemainingValue:string="0.0";
  currentdate:any;
  registeredEamiId: any;
  loginName:string
  oldRemainingAmount: any;
  showHideValidityDate: boolean;
  databaseRemainingAmount:any
 
  constructor(private ngxService: NgxUiLoaderService,private route:Router,private service:AccountService,private servicedata:TransferdataService
   ) { 
    this.subscription = route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !route.navigated;
      }
  });
   // this.reset()

   }   
  

  //todo2/1/23
  async ngOnInit() {
  debugger
  
    this.servicedata.currentApprovalStageMessage.subscribe(msg => this.message = msg);
    if(this.message=="Basic device is required!")
    {
     this.message = localStorage.getItem('uMassage') as string;
    }
    else
    {
     localStorage.setItem('uMassage', this.message.toString());

    }
    this.servicedata.selectedPlanPricermessage.subscribe(msg => this.finalcostfromSubsUI = Number(msg).toFixed(2));
    if(this.finalcostfromSubsUI=="NaN")
     {
      this.finalcostfromSubsUI = localStorage.getItem('uFinalPrise') as string;
     }
     else
     {
      localStorage.setItem('uFinalPrise', this.finalcostfromSubsUI.toString());

     }
    this.servicedata.sendRegisteredEmailIdAsMessage.subscribe(msg=>this.registeredEamiId=msg);
     if(this.registeredEamiId=="N/A")
     {
      this.registeredEamiId= localStorage.getItem('uEmail');
     }
     else
     {
      localStorage.setItem('uEmail', this.registeredEamiId.toString());

     }
//to get username
     this.servicedata.sendLoginUserNameAsMessage.subscribe(msg=>this.loginName=msg);
     if(this.loginName=="N/A")
     {
      this.loginName= localStorage.getItem('uName') as string;
     }
     else
     {
      localStorage.setItem('uName', this.loginName.toString());

     }
     //to get total Device Count
     this.servicedata.totalNumberofDeviceCountInfo.subscribe(msg=>this.totalDeviceCount=msg);
     if(this.totalDeviceCount=="N/A")
     {
      this.totalDeviceCount= localStorage.getItem('uTotalDeviceCount') as string;
     }
     else
     {
      localStorage.setItem('uTotalDeviceCount', this.totalDeviceCount.toString());

     }
    this.ngxService.start();
    this.getSubscriptionDetails();
    
    //todo3jan-2023
    
    setTimeout( () => {
       this.getUserSubscriptionDetails();
     
    }, 1000);
    //await this.getUserSubscriptionDetails();
    this.ngxService.stop();
    this.isSubscription=true;
    
    this.service.model.subscribe(data => {
      console.log("transfereddata",data);
      this.registerUserModel = data;
    });
     
 
  }
  
  //this function is used to get Subscription Deatil.
  getSubscriptionDetails(){
    //this.ngxService.start();
    var subsciptionDetails = this.service.getSupsriptionPrice().subscribe(res=>{
      debugger
      if(res.ResponseCode == 200){
       if(res.Data[0].PlanId==1)
       {
           this.silverBasePrice=this.firsSubscripptionPrice=res.Data[0].PriceofSubscription;
            this.silverAddonPrice=this.secondSubscriptionPrice= res.Data[0].AddonPrice;
            this.silverDevices=this.totalSubscription= res.Data[0].NoofDevices;
            this.silverDevices1=this.totalSubscription1=res.Data[0].NoofDevices;
            
            this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
            this.SubscriptionNoteValue="Based on subscription type: Silver subscription comes with "+ this.silverDevices + " devices, and user can add 5 more additional devices. "
            
          }
       
          if(res.Data[1].PlanId==2){

            this.gfirsSubscripptionPrice=this.firsSubscripptionPrice=res.Data[1].PriceofSubscription;
            this.gsecondSubscriptionPrice =this.secondSubscriptionPrice= res.Data[1].AddonPrice;
            this.gtotalSubscription=this.totalSubscription= res.Data[1].NoofDevices;
            this.gtotalSubscription1 =this.totalSubscription1=res.Data[1].NoofDevices;
            //this.NoteValue="Note:- Each additional device will adjust with 5 (+/-) button.";
            this.NoteValue="Note:- Each additional device will adjust 5 with (+/-) button.";
            this.SubscriptionNoteValue="Based on subscription type: Gold subscription comes with "+ this.gtotalSubscription + " devices, and user can add 50 more additional devices. "
           }

           //added todo7Dec for Bronze Subscription

           if(res.Data[2].PlanId==3)
           {
              this.bronzeBasePrice=this.firsSubscripptionPrice=res.Data[2].PriceofSubscription;
              this.bronzeAddonPrice=this.secondSubscriptionPrice= res.Data[2].AddonPrice;
              this.bronzeDevices=this.totalSubscription= res.Data[2].NoofDevices;
              this.bronzeDevices1=this.totalSubscription1=res.Data[2].NoofDevices
              this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
              this.SubscriptionNoteValue="Based on subscription type: Bronze subscription comes with "+ this.bronzeDevices + " devices, and user can add 4 more additional devices. "
            }
      }

    },(err:HttpErrorResponse)=>{
    alert("This is the error response for the otp verification");
    }
    )
    //this.ngxService.stop();
  }
  //todo 3jan-23
    //this function is used to get  registered UserSubscription Deatil.
   getUserSubscriptionDetails(){
//this.ngxService.start();
    var subsciptionDetails = this.service.getuserSupsriptionPrice().subscribe(res=>{
  debugger
      if(res.ResponseCode == 200){
        if((res.Data[0].AddonPrice!="0")||(res.Data[0].PlanName!=null)){

           if(res.Data[0].PlanName=="Silver Subscription"){
            this.UsersilverBasePrice=this.firsSubscripptionPrice=this.silverBasePrice;
            this.SetAgaintheExistingPlan=this.userPlan=res.Data[0].PlanName;
            this.totalSubscription=this.silverDevices;
            this.selectValue =1;
            this.addonDevice=res.Data[0].AddonDevice;
            this.databaseRemainingAmount=this.remainingAmount=Number(res.Data[0].Remainingamount).toFixed(2).toString();
            this.UsersilverDevices=this.totalSubscription1= res.Data[0].NumberOfSubscriber;
            this.secondSubscriptionPrice=Number(this.silverAddonPrice).toFixed(2);
            this.UsersilverAddonPrice= Number(res.Data[0].AddonPrice).toFixed(2);
            this.ValidityofSubscription=new String(res.Data[0].Validity).substring(0,10);
            const TempplanValidity= new Date(this.ValidityofSubscription);
            const now= new Date();
            const currentdate=new Date(now);
             //if addon is not added
            if(Number(this.UsersilverAddonPrice) > 0)
                {
                       this.onedaycostofSilverAddonDevice=Number(this.secondSubscriptionPrice)/30;
                      this.onedaycostofSilverAddonDevice=(Number(this.onedaycostofSilverAddonDevice) * Number(this.addonDevice)).toFixed(2)
                }
             else{
                      this.onedaycostofSilverAddonDevice="0.00"
                 }
           if(TempplanValidity<=currentdate)
           {
            this.totalDevice= (this.secondSubscriptionPrice*this.addonDevice).toFixed(2);
            this.ExistingPlanPrice=this.finalPrice=(Number(this.totalDevice) + Number(this.firsSubscripptionPrice)).toFixed(2);
            this.UsersilverDevices=this.totalSubscription1= Number(this.addonDevice) + Number(this.silverDevices);
           }
           else{
                    
           this.onedaycostofSilverbaseDevice=Number(this.silverBasePrice)/30;
           this.secondSubscriptionPrice=Number(this.silverAddonPrice).toFixed(2);
           this.onedaycostofSilverAddonDevice= this.addonDevice * Number(this.secondSubscriptionPrice)/30;
           
           this.totalDevice=this.UsersilverAddonPrice;
           this.ExistingPlanPrice=this.finalPrice=Number(res.Data[0].Price1).toFixed(2).toString();
           }
          
          // this.ExistingPlanPrice=this.finalPrice=(Number(this.UsersilverAddonPrice) + Number(this.firsSubscripptionPrice)).toFixed(2).toString();
           
           
           //this.oldRemainingAmount=res.Data[0].remainingAmount;
          
           this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
           this.SubscriptionNoteValue="Based on subscription type: Silver subscription comes with "+ this.silverDevices + " devices, and user can add 5 more additional devices."
           }     
         else if(res.Data[0].PlanName=="Gold Subscription"){
            this.SetAgaintheExistingPlan= this.userPlan=res.Data[0].PlanName;
            this.selectValue =5;
            this.totalSubscription=this.gtotalSubscription;
            this.secondSubscriptionPrice=this.gsecondSubscriptionPrice;
            this.guserfirsSubscripptionPrice= this.firsSubscripptionPrice=this.gfirsSubscripptionPrice;
            this.onedaycostofGoldbaseDevice=Number(this.guserfirsSubscripptionPrice)/30;//oneday priceof one device
            
            this.gusersecondSubscriptionPrice=Number(res.Data[0].AddonPrice).toFixed(2);
            this.addonDevice=res.Data[0].AddonDevice;  
            this.ValidityofSubscription=new String(res.Data[0].Validity).substring(0,10);  
            //this.totalDevice=this.gusersecondSubscriptionPrice; 
             this.ExistingPlanPrice=this.finalPrice=Number(res.Data[0].Price1).toFixed(2).toString();
             this.databaseRemainingAmount=this.remainingAmount=Number(res.Data[0].Remainingamount).toFixed(2).toString(); 
            //this.ExistingPlanPrice=this.finalPrice=(Number(this.gusersecondSubscriptionPrice) + Number(this.firsSubscripptionPrice)).toFixed(2).toString();
                         
            this.gusertotalSubscription1=this.totalSubscription1 =res.Data[0].NumberOfSubscriber; 
            //if the subscription expired , it show the one month plan value 
            const TempplanValidity= new Date(this.ValidityofSubscription);
            const now= new Date();
            const currentdate=new Date(now);
            if(Number(this.gusersecondSubscriptionPrice) > 0)
            {
            this.onedaycostofGoldAddonDevice=Number(this.gsecondSubscriptionPrice)/30;
            this.onedaycostofGoldAddonDevice=this.onedaycostofGoldAddonDevice * (Number(this.addonDevice)/this.selectValue)
            }
            else
            {
              this.onedaycostofGoldAddonDevice="0.00"
            }
           if(TempplanValidity<currentdate)
           {
            this.gusersecondSubscriptionPrice=Number(this.gsecondSubscriptionPrice).toFixed(2);
            this.totalDevice= this.secondSubscriptionPrice*this.addonDevice;
            this.ExistingPlanPrice=this.finalPrice=(Number(this.totalDevice) + Number(this.firsSubscripptionPrice)).toFixed(2);
            this.gtotalSubscription=this.totalSubscription1= Number(this.addonDevice) + Number(this.gtotalSubscription);
           }
           else{
                    
           this.onedaycostofGoldbaseDevice=Number(this.gfirsSubscripptionPrice)/30;
           this.secondSubscriptionPrice=Number(this.gsecondSubscriptionPrice).toFixed(2);
           this.onedaycostofSilverAddonDevice=Number(this.secondSubscriptionPrice)/30;
           this.gusersecondSubscriptionPrice= Number(res.Data[0].AddonPrice).toFixed(2);
           this.totalDevice=this.gusersecondSubscriptionPrice;
           this.ExistingPlanPrice=this.finalPrice=Number(res.Data[0].Price1).toFixed(2).toString();

           }
                       
            //this.NoteValue="*Note:- Each additional device will adjust with 5 (+/-) button.";
            this.NoteValue="Note:- Each additional device will adjust 5 with (+/-) button.";
            this.SubscriptionNoteValue="Based on subscription type: Gold subscription comes with "+ this.gtotalSubscription + " devices, and user can add 50 more additional devices. "
           }
           //todo7Dec23
           else if(res.Data[0].PlanName=="Bronze Subscription"){
            debugger
            this.UserbronzeBasePrice=this.firsSubscripptionPrice=this.bronzeBasePrice;
            this.SetAgaintheExistingPlan=this.userPlan=res.Data[0].PlanName;
            this.totalSubscription=this.bronzeDevices;
            this.selectValue =1;
            this.addonDevice=res.Data[0].AddonDevice;
            this.databaseRemainingAmount=this.remainingAmount=Number(res.Data[0].Remainingamount).toFixed(2).toString();
            this.UserBronzeDevices=this.totalSubscription1= res.Data[0].NumberOfSubscriber;
            this.secondSubscriptionPrice=Number(this.bronzeAddonPrice).toFixed(2);
            this.UserbronzeAddonPrice= Number(res.Data[0].AddonPrice).toFixed(2);
            this.ValidityofSubscription=new String(res.Data[0].Validity).substring(0,10);
            const TempplanValidity= new Date(this.ValidityofSubscription);
            const now= new Date();
            const currentdate=new Date(now);
             //if addon is not added
            if(Number(this.UserbronzeAddonPrice) > 0)
                {
                       this.onedaycostofbronzeAddonDevice=Number(this.secondSubscriptionPrice)/30;
                      this.onedaycostofbronzeAddonDevice=(Number(this.onedaycostofbronzeAddonDevice) * Number(this.addonDevice)).toFixed(2)
                }
             else{
                      this.onedaycostofbronzeAddonDevice="0.00"
                 }
           if(TempplanValidity<=currentdate)
           {
            this.totalDevice= (this.secondSubscriptionPrice*this.addonDevice).toFixed(2);
            this.ExistingPlanPrice=this.finalPrice=(Number(this.totalDevice) + Number(this.firsSubscripptionPrice)).toFixed(2);
            this.UserBronzeDevices=this.totalSubscription1= Number(this.addonDevice) + Number(this.bronzeDevices);
           }
           else{
                    
           this.onedaycostofbronzebaseDevice=Number(this.bronzeBasePrice)/30;
           this.secondSubscriptionPrice=Number(this.bronzeAddonPrice).toFixed(2);
           this.onedaycostofbronzeAddonDevice= this.addonDevice * Number(this.secondSubscriptionPrice)/30;
           this.totalDevice=this.UserbronzeAddonPrice;
           this.ExistingPlanPrice=this.finalPrice=Number(res.Data[0].Price1).toFixed(2).toString();
           }
          
          // this.ExistingPlanPrice=this.finalPrice=(Number(this.UsersilverAddonPrice) + Number(this.firsSubscripptionPrice)).toFixed(2).toString();
          //this.oldRemainingAmount=res.Data[0].remainingAmount;
           this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
           this.SubscriptionNoteValue="Based on subscription type: Bronze subscription comes with "+ this.bronzeDevices + " devices, and user can add 1 more additional devices."
           } 
           else{
           const now= new Date();
           const currentdate=new Date(now);
           this.userPlan="Silver Subscription"
           this.selectValue =1;
           this.firsSubscripptionPrice=this.silverBasePrice;
           this.secondSubscriptionPrice= this.silverAddonPrice;
            this.totalSubscription= this.silverDevices;
            this.totalSubscription1=this.silverDevices1;
            this.finalPrice=this.silverBasePrice;
            this.totalDevice="0.0"
            this.databaseRemainingAmount=this.remainingAmount="0.00";
            this.addonDevice=0;
            this.ValidityofSubscription=currentdate;
            this.RenewSubscription =true;
           
            //this.NoteValue="*Note:- Each additional device will adjust with 1 (+/-) button.";
            this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
            this.SubscriptionNoteValue="Based on subscription type: Silver subscription comes with "+ this.silverDevices + " devices, and user can add 5 more additional devices. "
          } 
         }
         const TempplanValidity= new Date(this.ValidityofSubscription);
         const now= new Date();
         const currentdate=new Date(now);
       if( TempplanValidity<currentdate)
         { debugger
          this.showHideValidityDate=false;
          this.RenewSubscription =true;
          this.ValidityofSubscription=currentdate;
         }
        else
        { debugger
                   
         this.ValidityofSubscription = moment(this.ValidityofSubscription);
         this.currentdate = moment(now);
       
         this.CheckRemaingDays = Math.abs(this.ValidityofSubscription.diff(this.currentdate, 'days')); 
         //this.CheckRemaingDays = Math.abs(this.ValidityofSubscription.diff("10/07/2023", 'days')); 
         this.CheckRemaingDays= Number(this.CheckRemaingDays);
         debugger
         this.tRemainingdate=this.CheckRemaingDays=this.CheckRemaingDays+1;
         //calculate remaining price of old subscription.
        //  var Rdate=this.CheckRemaingDays+1
        //   this.DateShow="for"+" "+Rdate+" "+"days."
          debugger
          if(!this.RenewSubscription)
          {
            this.showHideValidityDate=true;
          if(this.userPlan=="Silver Subscription")
          {
            this.CurrentSilverSubscriptionRemainingDaysAmount=((Number(this.onedaycostofSilverbaseDevice)* this.CheckRemaingDays)+(Number(this.onedaycostofSilverAddonDevice)* this.CheckRemaingDays))
            this.CurrentSilverSubscriptionRemainingDaysAmount=Number(this.CurrentSilverSubscriptionRemainingDaysAmount).toFixed(2)
            this.NoteValueforCurrentremainingAmount="*This subscription currently has " + this.CheckRemaingDays +" remaining days of a values of $ "+this.CurrentSilverSubscriptionRemainingDaysAmount
          }
          else if(this.userPlan=="Gold Subscription"){
            this.CurrentGoldSubscriptionRemainingDaysAmount=((Number(this.onedaycostofGoldbaseDevice)* this.CheckRemaingDays)+(Number(this.onedaycostofGoldAddonDevice)* this.CheckRemaingDays))
            this.CurrentGoldSubscriptionRemainingDaysAmount=Number(this.CurrentGoldSubscriptionRemainingDaysAmount).toFixed(2)
            this.NoteValueforCurrentremainingAmount="*This subscription currently has " + this.CheckRemaingDays +" remaining days of a values of $ "+this.CurrentGoldSubscriptionRemainingDaysAmount
          }
          else{
            this.CurrentbronzeSubscriptionRemainingDaysAmount=((Number(this.onedaycostofbronzebaseDevice)* this.CheckRemaingDays)+(Number(this.onedaycostofbronzeAddonDevice)* this.CheckRemaingDays))
            this.CurrentbronzeSubscriptionRemainingDaysAmount=Number(this.CurrentbronzeSubscriptionRemainingDaysAmount).toFixed(2)
            this.NoteValueforCurrentremainingAmount="*This subscription currently has " + this.CheckRemaingDays +" remaining days of a values of $ "+this.CurrentbronzeSubscriptionRemainingDaysAmount
          }
          
        }
        
        }   
    }

    },(err:HttpErrorResponse)=>{
    alert("this is the error response for the otp verification");
    }
    
    )
    debugger
    
  }
 
  //This function used to add addon device according to subscription.
 plus()
   { 

     debugger 
     if(this.addonDevice != null)
      {
        this.plan_qty=Number(this.addonDevice);
      }
    if(this.RenewSubscription){
            
      this.route.navigate(['subscriptiondetails']);
    }
    else if(this.CheckRemaingDays>=0 && this.ChangeExistingPlanSubscription)
    {
      this.btnPlus.disabled=true;
      if(this.selectedValue=="Silver Subscription")
      {
        this.selectValue=1;
        this.route.navigate(['subscriptiondetails']);
      }
      else{
        this.selectValue=5;
      this.route.navigate(['subscriptiondetails']);
      }
    }
    else if(this.CheckRemaingDays>0)
    {var result= confirm(" Do you want to add more add-on devices to your current plan?")
     if(result){
//todo6sep23
      this.servicedata.sendRegisteredEmailOnPaymentUI(this.registeredEamiId)          
      this.servicedata.updateApprovalMessage(this.totalSubscription1)
      this.servicedata.sendFinaltotalSelectedPrice(this.finalPrice)
      this.servicedata.sendTotalDeviceCountInfo(this.loginName)
      this.route.navigate(['additionaladdon']);
      }
      else{

       this.route.navigate(['subscriptiondetails']);
       this.selectValue=0;

      }
     }    
     if( this.plan_qty < 5  && this.selectValue==1 && this.userPlan=="Silver Subscription") 
     {
      if(this.addonDevice != null)
      {
        this.plan_qty=Number(this.addonDevice);
      }
     this.plan_qty = this.plan_qty + this.selectValue;
     //this.defaultPrice();
     this.addonDevice=this.plan_qty
     this.totalDevice = this.addonDevice * this.secondSubscriptionPrice
     this.totalDevice=Number(this.totalDevice).toFixed(2)
     this.totalSubscription1 =  Number(this.totalSubscription )+  this.addonDevice;
   
     if(this.ChangeExistingPlanSubscription)
     {
     var costOfOneDay=Number(this.ExistingPlanPrice)/30;
     this.remainingAmount=costOfOneDay*this.CheckRemaingDays;
     this.finalPrice1=this.finalPrice-this.remainingAmount;
     }
     this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
     this.finalPrice1=this.finalPrice-this.remainingAmount;
     this.finalPrice1=Number(this.finalPrice1).toFixed(2);   
     this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
     this.finalPrice=Number(this.finalPrice).toFixed(2)   
     }
     else if( this.plan_qty < 4  && this.selectValue==1 && this.userPlan=="Bronze Subscription") 
     {
      if(this.addonDevice != null)
      {
        this.plan_qty=Number(this.addonDevice);
      }
      this.plan_qty = this.plan_qty + this.selectValue;
     //this.defaultPrice();
     this.addonDevice=this.plan_qty
     this.totalDevice = this.addonDevice * this.secondSubscriptionPrice
     this.totalDevice=Number(this.totalDevice).toFixed(2)
     this.totalSubscription1 =  Number(this.totalSubscription )+  this.addonDevice;
   
     if(this.ChangeExistingPlanSubscription)
     {
     var costOfOneDay=Number(this.ExistingPlanPrice)/30;
     this.remainingAmount=costOfOneDay*this.CheckRemaingDays;
     this.finalPrice1=this.finalPrice-this.remainingAmount;
     }
     this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
     this.finalPrice1=this.finalPrice-this.remainingAmount;
     this.finalPrice1=Number(this.finalPrice1).toFixed(2);   
     this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
     this.finalPrice=Number(this.finalPrice).toFixed(2)   
     }
     else if(this.plan_qty < 50  && this.selectValue==5){
    if(this.addonDevice != null)
      {
        this.plan_qty=Number(this.addonDevice);
       }
      this.plan_qty = this.plan_qty + this.selectValue;
   //this.defaultPrice();
   this.addonDevice=this.plan_qty
   this.OneGoldAddonPrice=Number(this.secondSubscriptionPrice)/5;
   this.totalDevice = this.addonDevice * this.OneGoldAddonPrice
   this.totalDevice=Number(this.totalDevice).toFixed(2)
   this.totalSubscription1 =  Number(this.totalSubscription )+  this.addonDevice;
   
   if(this.ChangeExistingPlanSubscription){
     debugger
     var costOfOneDay=Number(this.ExistingPlanPrice)/30;
     this.remainingAmount=costOfOneDay*this.CheckRemaingDays;
     this.remainingAmount=this.remainingAmount.toFixed(2)
     }
   this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
   this.finalPrice1=this.finalPrice-this.remainingAmount;
   this.finalPrice1=Number(this.finalPrice1).toFixed(2);   
   this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
   this.finalPrice=Number(this.finalPrice).toFixed(2)
     }
  }
    
 //This function used to  minus addon device according to subscription.
  minus()
  {
    const TempplanValidity= new Date(this.ValidityofSubscription);
         const now= new Date();
         const currentdate=new Date(now);
     if( TempplanValidity>currentdate)
         { debugger
           //to hide StepperValue
         this.btnMinus.disabled=true;
       }
    else{
    if(this.addonDevice != null)
      {
        this.plan_qty=Number(this.addonDevice);
      }
     this.finalPrice1=0;
     debugger
     if(this.plan_qty >= 1 && this.selectValue==1){
      this.plan_qty = this.plan_qty-1;
      //this.defaultPrice();
   this.addonDevice=this.plan_qty
   this.totalDevice = this.addonDevice * this.secondSubscriptionPrice
   this.totalDevice=Number(this.totalDevice).toFixed(2)
   this.totalSubscription1 =  Number(this.totalSubscription )+  this.addonDevice;
   this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
   this.finalPrice=Number(this.finalPrice).toFixed(2)
   if(this.ChangeExistingPlanSubscription){
    debugger
    var costOfOneDay=Number(this.ExistingPlanPrice)/30;
    this.remainingAmount=costOfOneDay*this.CheckRemaingDays;
    this.remainingAmount=this.remainingAmount.toFixed(2)
    this.finalPrice1=this.finalPrice-this.remainingAmount;
    this.finalPrice1=this.finalPrice1.toFixed(2);
    }
   this.finalPrice=Number(this.finalPrice).toFixed(2)
  }

    if(this.plan_qty >= 5 && this.selectValue==5){

     this.plan_qty = this.plan_qty - this.selectValue;
     
     //this.defaultPrice();
     this.addonDevice=this.plan_qty
     this.OneGoldAddonPrice=Number(this.secondSubscriptionPrice)/5;
     this.totalDevice = this.addonDevice * this.OneGoldAddonPrice
     this.totalDevice=Number(this.totalDevice).toFixed(2)
     this.totalSubscription1 =  Number(this.totalSubscription )+  this.addonDevice;
     this.finalPrice= Number (this.totalDevice) + Number(this.firsSubscripptionPrice)
     this.finalPrice=Number(this.finalPrice).toFixed(2)
     if(this.ChangeExistingPlanSubscription){
      debugger
      var costOfOneDay=Number(this.ExistingPlanPrice)/30;
      this.remainingAmount=costOfOneDay*this.CheckRemaingDays;
      this.remainingAmount=this.remainingAmount.toFixed(2)
      this.finalPrice1=this.finalPrice-this.remainingAmount;
      this.finalPrice1=this.finalPrice1.toFixed(2);
      }
     this.finalPrice=Number(this.finalPrice).toFixed(2)
     }

  }
}
  CancleClick()
  {
    debugger
    window.location.reload();
  }
 //this function is used to  send  selected data in payament page.
 registerClick()
    {
     debugger
     this.ValidityofSubscription = moment(this.ValidityofSubscription);
     const now= new Date();
     this.currentdate = moment(now);  
    if((this.ValidityofSubscription>this.currentdate)&&(this.SetAgaintheExistingPlan==this.userPlan))
     {
      this.ValidityofSubscription= moment(this.ValidityofSubscription).format('MM/DD/YYYY');
       alert("You have already taken this month subscription, your subscription is valid till " + this.ValidityofSubscription + ". You can add add-on devices.")
     }
     else{
      //to check selected device > to device count from database.
      debugger
      // if(this.totalDeviceCount > this.totalSubscription1){

      //   var deleteDevivecs=(Number(this.totalDeviceCount)-Number(this.totalSubscription1))
      //   alert("Your current subscription's devices is less than from your old subscription's devices. So please deactivate"+" "+ deleteDevivecs +" "+" devices before taking the Subscription.")

      // }
    //  else{//added29Nov23
    if(this.btnRegisterText=='Continue')
         {
       
          if(!this.ChangeExistingPlanSubscription)
            { 
             this.noOfAdditionalAddonDevice="0";
             this.AdditionalDeviceCost="0.00";
            }
         else
           {
            this.noOfAdditionalAddonDevice="0";
            this.AdditionalDeviceCost="0.00";
           }
      
       if(Number(this.setRemainingValue) > Number(this.finalPrice)){      
        //todo 12sept23
        this.remainingAmount=this.setRemainingValue;   
        this.servicedata.sendRegisteredEmailOnPaymentUI(this.registeredEamiId)        
       this.servicedata.updateApprovalMessage(this.totalSubscription1)
       this.servicedata.sendFinaltotalSelectedPrice(this.finalPrice)
       this.servicedata.sendAddonDeviceOnPaymentUI(this.addonDevice)
       this.servicedata.sendAddonPriceOnPaymentUI(this.totalDevice)
       this.servicedata.sendUserPlanOnPaymentUI(this.userPlan)
       this.servicedata.sendPlanValidity(this.ValidityofSubscription) 
    //to send the number and cost of Addition device in payment page
      this.servicedata.sendAdditionalAddonDeviceUi(this.noOfAdditionalAddonDevice)
      this.servicedata.sendAdditionalAddonDevicePriceUi(this.AdditionalDeviceCost)
      this.servicedata.sendRemaingAmountofOldPlan(this.remainingAmount)
      this.servicedata.sendAdjustedAmountofCurrentPlan(this.finalPrice1)
      const TempplanValidity= new Date(this.ValidityofSubscription);
      const now= new Date();
      const currentdate=new Date(now);
     
      if(TempplanValidity<=currentdate)
       {
        this.route.navigate(['payment']);
       }
       else if(this.ChangeExistingPlanSubscription)
       {
       this.route.navigate(['payment']);

       }

       // alert("Before continuing to change plan, you have to add more add-on devices to adjust the remaining amount $" +this.remainingAmount+".")
     }
       else if(this.RenewSubscription)
       {this.setRemainingValue=this.remainingAmount;
       
        if(Number(this.remainingAmount) > (Number(this.finalPrice)))
        {
          this.remainingAmount=Math.abs(Number(this.finalPrice) - (Number(this.remainingAmount)))
          
       this.remainingAmount=Number(this.remainingAmount).toFixed(2)
          alert("Your subscription amount has been adjusted with your remaining amount $" +this.setRemainingValue +", credit balace amount is $"+ this.remainingAmount)
          this.finalPrice1=-Number(this.remainingAmount)
          this.remainingAmount=this.setRemainingValue;  
         
        }
        else
        {
          var currentSubscriptionAmount=(Number(this.finalPrice) - (Number(this.remainingAmount)))
          this.finalPrice1=currentSubscriptionAmount.toFixed(2)
          this.remainingAmount="0.00"
         }
        this.remainingAmount=this.setRemainingValue;   
       this.servicedata.sendRegisteredEmailOnPaymentUI(this.registeredEamiId)        
       this.servicedata.updateApprovalMessage(this.totalSubscription1)
       this.servicedata.sendFinaltotalSelectedPrice(this.finalPrice)
       this.servicedata.sendAddonDeviceOnPaymentUI(this.addonDevice)
       this.servicedata.sendAddonPriceOnPaymentUI(this.totalDevice)
       this.servicedata.sendUserPlanOnPaymentUI(this.userPlan)
       this.servicedata.sendPlanValidity(this.ValidityofSubscription) 
    //to send the number and cost of Addition device in payment page
       this.servicedata.sendAdditionalAddonDeviceUi(this.noOfAdditionalAddonDevice)
      this.servicedata.sendAdditionalAddonDevicePriceUi(this.AdditionalDeviceCost)
      this.servicedata.sendRemaingAmountofOldPlan(this.remainingAmount)
      this.servicedata.sendAdjustedAmountofCurrentPlan(this.finalPrice1)
      this.route.navigate(['payment']);
      }
       else{
        debugger
        this.remainingAmount="0.00"
        if((Number(this.remainingAmount<=0) && (this.ChangeExistingPlanSubscription))||( this.remainingAmount=="N/A" )) 
         {
           this.remainingAmount=this.setRemainingValue;   
           this.servicedata.sendRegisteredEmailOnPaymentUI(this.registeredEamiId)        
          this.servicedata.updateApprovalMessage(this.totalSubscription1)
          this.servicedata.sendFinaltotalSelectedPrice(this.finalPrice)
          this.servicedata.sendAddonDeviceOnPaymentUI(this.addonDevice)
          this.servicedata.sendAddonPriceOnPaymentUI(this.totalDevice)
          this.servicedata.sendUserPlanOnPaymentUI(this.userPlan)
          this.servicedata.sendPlanValidity(this.ValidityofSubscription) 
       //to send the number and cost of Addition device in payment page
          this.servicedata.sendAdditionalAddonDeviceUi(this.noOfAdditionalAddonDevice)
         this.servicedata.sendAdditionalAddonDevicePriceUi(this.AdditionalDeviceCost)
         this.servicedata.sendRemaingAmountofOldPlan(this.remainingAmount)
         this.servicedata.sendAdjustedAmountofCurrentPlan(this.finalPrice1)
         const TempplanValidity= new Date(this.ValidityofSubscription);
         const now= new Date();
         const currentdate=new Date(now);
        
         if(TempplanValidity<=currentdate)
          {
           this.route.navigate(['payment']);
          }
        else if(this.ChangeExistingPlanSubscription)
          {
          this.route.navigate(['payment']);

          }
        else
         {
        
         var result= confirm("Your current plan is active for this month. Do you want to continue? If so, your new plan will update in the next billing cycle!");
         
         if(result)
         {
         
          this.ngxService.start();
          this.route.navigate(['payment']);
          this.ngxService.stop();
         }
         else
         {          
           this.ngxService.start();
           window.location.reload();
           this.ngxService.stop();
         }
        }     
               
         }
        else{
          this.remainingAmount="0.00";
           const TempplanValidity= new Date(this.ValidityofSubscription);
           const now= new Date();
           const currentdate=new Date(now);
           this.servicedata.sendRegisteredEmailOnPaymentUI(this.registeredEamiId)  
           this.servicedata.updateApprovalMessage(this.totalSubscription1)
           this.servicedata.sendFinaltotalSelectedPrice(this.finalPrice)
           this.servicedata.sendAddonDeviceOnPaymentUI(this.addonDevice)
           this.servicedata.sendAddonPriceOnPaymentUI(this.totalDevice)
           this.servicedata.sendUserPlanOnPaymentUI(this.userPlan)
           this.servicedata.sendPlanValidity(this.ValidityofSubscription) 
           this.servicedata.sendAdditionalAddonDeviceUi(this.noOfAdditionalAddonDevice)
           this.servicedata.sendAdditionalAddonDevicePriceUi(this.AdditionalDeviceCost)
           this.servicedata.sendRemaingAmountofOldPlan(this.remainingAmount)
           this.servicedata.sendAdjustedAmountofCurrentPlan(this.finalPrice1)
          
          if(TempplanValidity<=currentdate)
          {
           this.route.navigate(['payment']);
          }
         else{
          var result= confirm("Your current plan is active for this month. Do you want to continue? If so, your new plan will be updated in the next billing cycle!");
         
          if(result)
          {           
           this.ngxService.start();
           this.route.navigate(['payment']);
           this.ngxService.stop();
          }
          else
          {          
            this.ngxService.start();
            window.location.reload();
            this.ngxService.stop();
          }
        }
       
         }
       }
     
     //}

    }
    }
   }

    //this code is used to select data from drop down.
     selectedLevel:any 
  
     data = [
      {id: 0, name: "Bronze Subscription"},
      {id: 1, name: "Silver Subscription"},
      {id: 2, name: "Gold Subscription"},
      {id: 3, name: "New Subscription"}
     ];
     
     selected()
     {  debugger
      
          this.selectedValue=this.selectedLevel.name
      if(this.selectedValue==this.SetAgaintheExistingPlan)//Plan not change.
         {
           window.location.reload();
         }
      else if( this.selectedValue=="New Subscription") //Sign up a new user.!
         {
            var result =confirm("Please open CYA Release Form iPad app and sign up to create a new subscription ")
           if(result)
           {
             this.route.navigate(['login']);
           }  
           else
           {
            window.location.reload();
           }
         }
      else{
          const TempplanValidity= new Date(this.ValidityofSubscription);
          const now= new Date();
          const currentdate=new Date(now);
         if(TempplanValidity>currentdate)
         {this.showHideCancel=true;
          this.showHideContinue=false;

          var Rdate=this.tRemainingdate+1
          this.DateShow="for"+" "+Rdate+" "+"days."
           //if(this.totalDeviceCount>Number("0"))
             //var result =confirm("if you select a new plan. Your active plan will be canceled now, and your selected plan will be active as of today. Do you want to continue? ")
          // {
             var result =confirm("If you change the existing plan, the existing registered iPads will be deactivated. You will need to sign-out and sign-back in with the iPad to reactivate the existing or new iPad.")
            debugger
            if(result)
            {this.AdditionalDeviceCost="0.0";
            this.noOfAdditionalAddonDevice="N/A";
              this.remainingAmount=this.databaseRemainingAmount
              this.selectedValue=this.selectedLevel.name
              this.ChangeExistingPlanSubscription=true;
              debugger
              
              this.CheckRemaingDays=this.tRemainingdate+1;
              if(this.remainingAmount!="0.00")
              {
              alert("You have an old remaining amount of $"+this.remainingAmount+". You can adjust this amount to add-on devices or change your current subscription.")
              }
             if(this.selectedValue==='Silver Subscription') //subscription adjust to  gold to silver or select the silver       
             {
               this.selectValue =1
               this.userPlan="Silver Subscription"
               this.plan_qty=0
               this.addonDevice=0;
               this.firsSubscripptionPrice=this.silverBasePrice
               this.secondSubscriptionPrice=this.silverAddonPrice
               this.totalDevice=0
               this.totalSubscription=this.silverDevices
                //todo12/9
               this.onedayCost=this.silverBasePrice/30;
               this.finalPrice=this.onedayCost*this.CheckRemaingDays;
               this.finalPrice=this.finalPrice.toFixed(2);
               this.setRemainingValue=this.remainingAmount;
                //todo19sept23 Adujusting Amount when , changing Subscription.
    
                this.ExistingPlanPrice=(Number(this.ExistingPlanPrice)/30)*(this.CheckRemaingDays-1)
                this.ExistingPlanPrice=Number(this.ExistingPlanPrice).toFixed(2)

                //when change subsciption bronze in the given condition...
                 if(this.SetAgaintheExistingPlan=='Bronze Subscription'){
                  if(Number(this.CurrentbronzeSubscriptionRemainingDaysAmount) > Number(this.finalPrice))
                  {
                    this.remainingAmount=((Number(this.CurrentbronzeSubscriptionRemainingDaysAmount)- Number(this.finalPrice))+Number(this.remainingAmount)).toFixed(2).toString();
                    if(this.setRemainingValue=="0.00")
                    {
                      this.setRemainingValue=this.CurrentbronzeSubscriptionRemainingDaysAmount;
                    }
                    //this.finalPrice=this.finalPrice.toFixed(2);
                    this.finalPrice1=-Number(this.remainingAmount);             
                  }
                  
                 else if(Number(this.CurrentbronzeSubscriptionRemainingDaysAmount) < Number(this.finalPrice))
                  {
                    var TfinalPrice=((Number(this.CurrentbronzeSubscriptionRemainingDaysAmount))- Number(this.finalPrice));
                       //tocheck the remaining amount value..
                       TfinalPrice=Math.abs(TfinalPrice);  //added19dec23
                    if(Number(this.remainingAmount) >= Number(TfinalPrice))
                    {
                      TfinalPrice=Math.abs(TfinalPrice);      
                      this.balanceAmount10= TfinalPrice-Number(this.remainingAmount); 
                      this.balanceAmount10=this.balanceAmount10.toFixed(2).toString();
                      this.remainingAmount=Math.abs(this.balanceAmount10);
                      this.finalPrice1=this.balanceAmount10;
                       //this.AdditionalDeviceCost="0.00";

                       //alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.remainingAmount)
                    }
                    else{
               
                     TfinalPrice=TfinalPrice - Number(this.remainingAmount);
                     this.finalPrice1=TfinalPrice;
                     this.finalPrice1= this.finalPrice1.toFixed(2).toString();
                     this.remainingAmount="0.00";
                     this.balanceAmount12=this.remainingAmount="0.00"
                     this.setRemainingValue=this.remainingAmount=this.CurrentbronzeSubscriptionRemainingDaysAmount//added19Dec23
                     //alert("Your existing subscription remaining amount has been adjusted with your new subscription, existing subscription remaining amount $"+ this.CurrentbronzeSubscriptionRemainingDaysAmount)//added19Dec23
                    //  alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.balanceAmount10)
                    }
                   
                  }
                }
               else if(this.SetAgaintheExistingPlan=='Gold Subscription'){
                  if(Number(this.CurrentGoldSubscriptionRemainingDaysAmount) > Number(this.finalPrice))
                  {
                    this.remainingAmount=((Number(this.CurrentGoldSubscriptionRemainingDaysAmount)- Number(this.finalPrice))+Number(this.remainingAmount)).toFixed(2).toString();
                    if(this.setRemainingValue=="0.00")
                    {
                      this.setRemainingValue=this.CurrentGoldSubscriptionRemainingDaysAmount;
                    }
                    //this.finalPrice=this.finalPrice.toFixed(2);
                    this.finalPrice1=-Number(this.remainingAmount);
                  
                  }
                 else if(Number(this.CurrentGoldSubscriptionRemainingDaysAmount) < Number(this.finalPrice))
                  {
                    var TfinalPrice=((Number(this.finalPrice))- Number(this.CurrentGoldSubscriptionRemainingDaysAmount));
                    if(Number(this.remainingAmount) >= Number(TfinalPrice))
                    {
                      TfinalPrice=Math.abs(TfinalPrice);
                      this.balanceAmount10= TfinalPrice-Number(this.remainingAmount); 
                      this.balanceAmount10=this.balanceAmount10.toFixed(2).toString();
                      this.remainingAmount=Math.abs(this.balanceAmount10);
                      this.finalPrice1=this.balanceAmount10;
                       //this.AdditionalDeviceCost="0.00";
                       alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.remainingAmount)
                    }
                   else
                    {
                     TfinalPrice=TfinalPrice - Number(this.remainingAmount);
                     this.finalPrice1=TfinalPrice;
                     this.finalPrice1=this.finalPrice1.toFixed(2);
                     this.remainingAmount=this.CurrentSilverSubscriptionRemainingDaysAmount;
                     if(Number(this.setRemainingValue)==0)
                     {
                      this.setRemainingValue=this.remainingAmount;
                     }
                   
                    }
                  }  

                }
                else{
                  //no code
                }
                this.totalSubscription1=this.silverDevices1
                this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
                this.SubscriptionNoteValue="Based on subscription type: Silver subscription comes with "+ this.silverDevices + " devices, and user can add 5 more additional devices. "
             }
             else if(this.selectedValue==='Bronze Subscription') //subscription adjust to  Bronze Subscription       
             {
               this.selectValue =1
               this.userPlan="Bronze Subscription"
               this.plan_qty=0
               this.addonDevice=0;
               this.firsSubscripptionPrice=this.bronzeBasePrice
               this.secondSubscriptionPrice=this.bronzeAddonPrice
               this.totalDevice=0
               this.totalSubscription=this.bronzeDevices
                //todo12/9
               this.onedayCost=this.bronzeBasePrice/30;
               this.finalPrice=this.onedayCost*this.CheckRemaingDays;
               this.finalPrice=this.finalPrice.toFixed(2);
               this.setRemainingValue=this.remainingAmount;
                //todo19sept23 Adujusting Amount when , changing Subscription.
    
                this.ExistingPlanPrice=(Number(this.ExistingPlanPrice)/30)*this.CheckRemaingDays
                this.ExistingPlanPrice=Number(this.ExistingPlanPrice).toFixed(2)
                //when change subsciption bronze in the given condition...
                if(this.SetAgaintheExistingPlan=='Silver Subscription'){
                  if(Number(this.CurrentSilverSubscriptionRemainingDaysAmount) > Number(this.finalPrice))
                  {
                    this.remainingAmount=((Number(this.CurrentSilverSubscriptionRemainingDaysAmount)- Number(this.finalPrice))+Number(this.remainingAmount)).toFixed(2).toString();
                    if(this.setRemainingValue=="0.00")
                    {
                      this.setRemainingValue=this.CurrentSilverSubscriptionRemainingDaysAmount;
                    }
                    //this.finalPrice=this.finalPrice.toFixed(2);
                    this.finalPrice1=-Number(this.remainingAmount);             
                  }
                  
                 else if(Number(this.CurrentSilverSubscriptionRemainingDaysAmount) < Number(this.finalPrice))
                  {
                    var TfinalPrice=((Number(this.CurrentSilverSubscriptionRemainingDaysAmount))- Number(this.finalPrice));
                       //tocheck the remaining amount value..
                       TfinalPrice=Math.abs(TfinalPrice);  
                    if(Number(this.remainingAmount) > Number(TfinalPrice))
                    {
                      TfinalPrice=Math.abs(TfinalPrice);      
                      this.balanceAmount10= TfinalPrice-Number(this.remainingAmount); 
                      this.balanceAmount10=this.balanceAmount10.toFixed(2).toString();
                      this.remainingAmount=Math.abs(this.balanceAmount10);
                      this.finalPrice1=this.balanceAmount10;
                       //this.AdditionalDeviceCost="0.00";
                       //alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.remainingAmount)
                    }
                    else{
               
                     TfinalPrice=this.TfinalPrice - Number(this.remainingAmount);
                     this.finalPrice1=TfinalPrice;
                     this.remainingAmount="0.00";
                     this.balanceAmount12=this.remainingAmount="0.00"
                     this.setRemainingValue=this.remainingAmount=this.CurrentSilverSubscriptionRemainingDaysAmount//todo20Dec23
                     //alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.balanceAmount10)
                    }
                   
                  }
                }
                else if(this.SetAgaintheExistingPlan=='Gold Subscription'){
                  if(Number(this.CurrentGoldSubscriptionRemainingDaysAmount) > Number(this.finalPrice))
                  {
                    this.remainingAmount=((Number(this.CurrentGoldSubscriptionRemainingDaysAmount)- Number(this.finalPrice))+Number(this.remainingAmount)).toFixed(2).toString();
                    if(this.setRemainingValue=="0.00")
                    {
                      this.setRemainingValue=this.CurrentGoldSubscriptionRemainingDaysAmount;
                    }
                    //this.finalPrice=this.finalPrice.toFixed(2);
                    this.finalPrice1=-Number(this.remainingAmount);
                  
                  }
                 else if(Number(this.CurrentGoldSubscriptionRemainingDaysAmount) < Number(this.finalPrice))
                  {
                    var TfinalPrice=((Number(this.finalPrice))- Number(this.CurrentGoldSubscriptionRemainingDaysAmount));
                    if(Number(this.remainingAmount) >= Number(TfinalPrice))
                    {
                      TfinalPrice=Math.abs(TfinalPrice);
                      this.balanceAmount10= TfinalPrice-Number(this.remainingAmount); 
                      this.balanceAmount10=this.balanceAmount10.toFixed(2).toString();
                      this.remainingAmount=Math.abs(this.balanceAmount10);
                      this.finalPrice1=this.balanceAmount10;
                       //this.AdditionalDeviceCost="0.00";
                       alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.remainingAmount)
                    }
                   else
                    {
                     TfinalPrice=TfinalPrice - Number(this.remainingAmount);
                     this.finalPrice1=TfinalPrice;
                     this.finalPrice1=this.finalPrice1.toFixed(2);
                     this.remainingAmount=this.CurrentGoldSubscriptionRemainingDaysAmount;
                     if(Number(this.setRemainingValue)==0)
                     {
                      this.setRemainingValue=this.remainingAmount;
                     }
                   
                    }
                  }  

                }
                else{
                  //no code
                }
            
                this.totalSubscription1=this.bronzeDevices1
                this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
                this.SubscriptionNoteValue="Based on subscription type: Bronze subscription comes with "+ this.bronzeDevices + " devices, and user can add 4 more additional devices. "
             }
             else //subscription adjust for silver to gold or select gold.
              {
                this.selectValue =5
                this.userPlan="Gold Subscription"
                this.plan_qty=0
                this.addonDevice=0;
                this.firsSubscripptionPrice=this.gfirsSubscripptionPrice
                this.secondSubscriptionPrice=this.gsecondSubscriptionPrice
                this.totalDevice=this.gtotalDevice
                this.totalSubscription=this.gtotalSubscription
                //todo12/9
                this.onedayCost=this.gfirsSubscripptionPrice/30;
    
                this.finalPrice=this.onedayCost*this.CheckRemaingDays;
                this.finalPrice=this.finalPrice.toFixed(2);
                this.setRemainingValue=this.remainingAmount;
                //todo19sept23 Adujusting Amount when , changing Subscription.
                this.ExistingPlanPrice=(Number(this.ExistingPlanPrice)/30)*this.CheckRemaingDays
                this.ExistingPlanPrice=Number(this.ExistingPlanPrice).toFixed(2)
                
                //when change subsciption bronze in the given condition...
                if(this.SetAgaintheExistingPlan=='Bronze Subscription'){
                  if(Number(this.CurrentbronzeSubscriptionRemainingDaysAmount) > Number(this.finalPrice))
                  {
                    this.remainingAmount=((Number(this.CurrentbronzeSubscriptionRemainingDaysAmount)- Number(this.finalPrice))+Number(this.remainingAmount)).toFixed(2).toString();
                    if(this.setRemainingValue=="0.00")
                    {
                      this.setRemainingValue=this.CurrentbronzeSubscriptionRemainingDaysAmount;
                    }
                    //this.finalPrice=this.finalPrice.toFixed(2);
                    this.finalPrice1=-Number(this.remainingAmount);             
                  }
                  
                 else if(Number(this.CurrentbronzeSubscriptionRemainingDaysAmount) < Number(this.finalPrice))
                  {
                    var TfinalPrice=((Number(this.CurrentbronzeSubscriptionRemainingDaysAmount))- Number(this.finalPrice));
                       //tocheck the remaining amount value..
                    if(Number(this.remainingAmount) >= Number(TfinalPrice))
                    {
                      TfinalPrice=Math.abs(TfinalPrice);      
                      this.balanceAmount10= TfinalPrice-Number(this.remainingAmount); 
                      this.balanceAmount10=this.balanceAmount10.toFixed(2).toString();
                      this.remainingAmount=Math.abs(this.balanceAmount10);
                      this.finalPrice1=this.balanceAmount10;
                       //this.AdditionalDeviceCost="0.00";
                       //alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.remainingAmount)
                    }
                    else{
               
                     TfinalPrice=this.TfinalPrice - Number(this.remainingAmount);
                     this.finalPrice1=TfinalPrice;
                     this.remainingAmount="0.00";
                     this.balanceAmount12=this.remainingAmount="0.00"
                    // alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.balanceAmount10)
                    }
                   
                  }
                }
                else if(this.SetAgaintheExistingPlan=='Silver Subscription'){
                  if(Number(this.CurrentSilverSubscriptionRemainingDaysAmount) > Number(this.finalPrice))
                  {
                    this.remainingAmount=((Number(this.CurrentSilverSubscriptionRemainingDaysAmount)- Number(this.finalPrice))+Number(this.remainingAmount)).toFixed(2).toString();
                    if(this.setRemainingValue=="0.00")
                    {
                      this.setRemainingValue=this.CurrentSilverSubscriptionRemainingDaysAmount;
                    }
                    //this.finalPrice=this.finalPrice.toFixed(2);
                    this.finalPrice1=-Number(this.remainingAmount);
                  
                  }
                 else if(Number(this.CurrentSilverSubscriptionRemainingDaysAmount) < Number(this.finalPrice))
                  {
                    var TfinalPrice=((Number(this.finalPrice))- Number(this.CurrentSilverSubscriptionRemainingDaysAmount));
                    if(Number(this.remainingAmount) >= Number(TfinalPrice))
                    {
                      TfinalPrice=Math.abs(TfinalPrice);
                      this.balanceAmount10= TfinalPrice-Number(this.remainingAmount); 
                      this.balanceAmount10=this.balanceAmount10.toFixed(2).toString();
                      this.remainingAmount=Math.abs(this.balanceAmount10);
                      this.finalPrice1=this.balanceAmount10;
                       //this.AdditionalDeviceCost="0.00";
                       //todo 26Dec23
                      alert("Your subscription amount has been adjusted with your remaining amount, balace amount is $"+ this.remainingAmount)
                    }
                   else
                    {
                     TfinalPrice=TfinalPrice - Number(this.remainingAmount);
                     this.finalPrice1=TfinalPrice;
                     this.finalPrice1=this.finalPrice1.toFixed(2);
                     this.remainingAmount=this.CurrentSilverSubscriptionRemainingDaysAmount;
                     if(Number(this.setRemainingValue)==0)
                     {
                      this.setRemainingValue=this.remainingAmount;
                     }
                   
                    }
                  }  

                }
                else{
                  //no code
                }
                 
                 this.totalSubscription1=this.gtotalSubscription1
                 this.NoteValue="Note:- Each additional device will adjust 5 with (+/-) button.";
                 this.SubscriptionNoteValue="Based on subscription type: Gold subscription comes with "+ this.gtotalSubscription + " devices, and user can add 50 more additional devices. "
               
             }
             
            }
            else{
              window.location.reload();
              }
         
         }
            ///renew  subscription  
         else {
         // this.selectValue=this.selectedLevel.name
          if(( this.selectedValue=='Silver Subscription')&&(this.RenewSubscription)){
          this.selectValue =1
          this.userPlan="Silver Subscription"
          this.plan_qty=0
          this.addonDevice=0;
          this.firsSubscripptionPrice=this.silverBasePrice
          this.secondSubscriptionPrice=this.silverAddonPrice
          this.totalDevice=0
          this.totalSubscription=this.silverDevices
         //this.onedayCost=this.silverBasePrice/30;
          //this.finalPrice=this.onedayCost*this.CheckRemaingDays;
          //this.finalPrice=this.finalPrice.toFixed(2);
          this.finalPrice=this.silverBasePrice
          this.totalSubscription1=this.silverDevices1
          //this.NoteValue="*Note:- Each additional device will adjust with 1 (+/-) button.";
          this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
          this.SubscriptionNoteValue="Based on subscription type: Silver subscription comes with "+ this.silverDevices + " devices, and user can add 5 more additional devices. "
          }
          else  if(( this.selectedValue=='Bronze Subscription')&&(this.RenewSubscription)){
            this.selectValue =1
            this.userPlan="Bronze Subscription"
            this.plan_qty=0
            this.addonDevice=0;
            this.firsSubscripptionPrice=this.bronzeBasePrice
            this.secondSubscriptionPrice=this.bronzeAddonPrice
            this.totalDevice=0
            this.totalSubscription=this.bronzeDevices
           //this.onedayCost=this.silverBasePrice/30;
            //this.finalPrice=this.onedayCost*this.CheckRemaingDays;
            //this.finalPrice=this.finalPrice.toFixed(2);
            this.finalPrice=this.bronzeBasePrice
            this.totalSubscription1=this.bronzeDevices1
            //this.NoteValue="*Note:- Each additional device will adjust with 1 (+/-) button.";
            this.NoteValue="Note:- Each additional device will adjust 1 with (+/-) button.";
            this.SubscriptionNoteValue="Based on subscription type: Bronze subscription comes with "+ this.bronzeDevices + " devices, and user can add 4 more additional devices. "
            }
          else if(( this.selectedValue=='Gold Subscription')&&(this.RenewSubscription))
          {
             this.selectValue =5
             this.userPlan="Gold Subscription"
             this.plan_qty=0
             this.addonDevice=0;
             this.firsSubscripptionPrice=this.gfirsSubscripptionPrice
             this.secondSubscriptionPrice=this.gsecondSubscriptionPrice;
             this.totalDevice=this.gtotalDevice
             this.totalSubscription=this.gtotalSubscription
            // this.onedayCost=this.gfirsSubscripptionPrice/30;
             //this.finalPrice=this.onedayCost*this.CheckRemaingDays;
              //this.finalPrice=this.finalPrice.toFixed(2);
             this.finalPrice=this.gfirsSubscripptionPrice
             this.totalSubscription1=this.gtotalSubscription1
             //this.NoteValue="*Note:- Each additional device will adjust with 5 (+/-) button.";
             this.NoteValue="Note:- Each additional device will adjust 5 with (+/-) button.";
            this.SubscriptionNoteValue="Based on subscription type: Gold subscription comes with "+ this.gtotalSubscription + " devices, and user can add 50 more additional devices. "
           } 
           
         }
      
       }
      }
    }






      
      
 
  






