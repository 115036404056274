<html lang="en">
    <head>
        <title>Add_AddonDevices</title>
        <base href="/subscriptiondetails">
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
    </head>
    <body>
        <div class="conatiner">            
            <h6 style="text-align: right; color: white; background-color: black;" ><a style="color: white; padding-right: 10px; padding-bottom: 10;" href="https://subscriptions.cyareleaseforms.com" >Logout</a>
                <!-- <h6 style="text-align: right; color: white; padding-right: 40px; background-color:rgba(22, 22, 22 );" ><a style="color: white; padding-right: 10px; bottom: 10px;" href="https://subscriptionsstaging.cyareleaseforms.com/" >Logout</a> -->
                 <br><br style="padding-left: 10px;"> Welcome  {{loginName}} <br>In Wallet ${{DatabaseRemainingValue}}   
                   
            </h6>   
                                
 <div class="select-plan">
    
    <div class="conatiner">
        <div class="plan-card">
             <div class="plan-card-header">
                
                Add-Additional Add-on Devices in existing plan!
             </div>
             <div class="col-12" style="text-align: right;">
                <a  style="color: red; padding-right: 5px; padding-left: 5px; padding-bottom: 2px; text-align: center; background-color:darkgray;border-width: 4px; border-radius:4px;  "href="#subscriptiondetails">Back</a>
             </div>

              <div class="plan-card-body">
                <div class="row ">

                    <div class="col-6">
                        <div>
                            <label> Select Subscription</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="dropdown custom_dropdown">
                            <select [(ngModel)]="selectedLevel" (change)="selected()" class="form-control">
                                <option class="dropdown-item" disabled selected value="undefined">{{userPlan}}
                                </option>
                                <option *ngFor="let item of data" [ngValue]="item"> {{item.name}}</option>
                            </select>
                        </div>
                    </div>

                 </div>


                 <div class="row my-2">

                    <div class="col-6">
                        <p class="mb-0">No. of Add-on Device</p>
                    </div>
                    <div class="col-6">
                        <div class="quantity-control" data-quantity="">
                            <button class="quantity-btn" data-quantity-minus="" (click)="minus()" id="btnMinus"><svg
                                    viewBox="0 0 409.6 409.6">
                                    <g>
                                        <g>
                                            <path
                                                d="M392.533,187.733H17.067C7.641,187.733,0,195.374,0,204.8s7.641,17.067,17.067,17.067h375.467 c9.426,0,17.067-7.641,17.067-17.067S401.959,187.733,392.533,187.733z" />
                                        </g>
                                    </g>
                                </svg></button>
                            <input type="number" class="quantity-input" data-quantity-target="" [(ngModel)]="plan_qty"
                                step="0" min="0" max="" name="quantity" disabled>
                            <button class="quantity-btn" data-quantity-plus="" (click)="plus()" id="btnPlus"><svg
                                    viewBox="0 0 426.66667 426.66667">
                                    <path
                                        d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0" />
                                </svg>
                            </button>
                        </div>
                    </div>

                 </div>

                 <div class="row">

                    <div class="col-6">
                        <p> Base Price For {{totalSubscription}} Devices {{userPlan}}.</p>

                    </div>
                    <div class="col-6">
                        <b>$ {{firsSubscripptionPrice}}</b>
                    </div>
                 </div>
                 <div class="row my-2">
                    <div class="col-sm-12">
                         <div class="col-sm-12 note-textalign">
                            <p><small class="text-danger">{{NoteValue}}</small></p>
                            </div>
                         <!-- <div class="col-sm-12 note-textalign" *ngIf="showHideRemainingAmountnote" >
                           <p><small class="text-danger">{{NoteValueforremainingAmount}}</small></p>
                         </div> -->
                      </div>
                   </div>

                 <div class="row">
                    <div class="col-6">
                        <p>Price For Add-on Devices </p>
                    </div>
                    <div class="col-6">
                        <b>$ {{secondSubscriptionPrice}}</b>
                    </div>
                 </div>
                 <div class="row">
                    <div class="col-6">
                        <p>Subscription valid till </p>
                    </div>
                    <div class="col-6">
                        <b>{{ValidityofSubscription | date:'MM-dd-yyyy'}}</b>
                    </div>
                </div>

                 <div class="row my-2">
                    <div class="col-6">
                        <p>Total Add-on Device/Cost($)</p>
                    </div>
                    <div class="col-6">
                       
                        <div class="row custom-space">
                            <div class="col-6" >
                                <input type="text" style="background-color: lightgray;"placeholder="0.0" name="" [(ngModel)]="addonDevice" disabled
                                    class="form-control">
                                <!-- <input type="text" placeholder="0.0" class="form-control" name="" [(ngModel)]="finalPrice" disabled>-->
                            </div>

                            <div class="col-6">
                                <input type="text" placeholder="0.0" style="background-color: lightgray;" name="" [(ngModel)]="totalDevice" disabled
                                    class="form-control">
                                <!-- <input type="text" placeholder="0.0" class="form-control" name="" [(ngModel)]="finalPrice" disabled>-->
                            </div>
                        </div>
                    </div>
                 </div>

                 <div class="row">
                    <div class="col-6">
                        <p>Total Device/Cost($)</p>
                    </div>
                    <div class="col-6">
                        <div class="row custom-space">
                            <div class="col-6">
                                <input type="text" placeholder="0.0" style="background-color: lightgray;"  class="form-control" name=""
                                    [(ngModel)]="totalSubscription1" disabled>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="0.0" style="background-color: lightgray;"  class="form-control" name=""
                                    [(ngModel)]="finalPrice" disabled>
                            </div>
                        </div>
                    </div>
                 </div>

                 <div class="row mt-4">
                    <div class="col-sm-12 text-center">

                        <button class="btn btn-primary" type="submit"
                            (click)="registerClick()">{{btnRegisterText}}</button>

                    </div>
                </div>
            </div>
        </div>


</div>
                </div>
              
        </div>
    </body>
</html>