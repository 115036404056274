
import {  Component,  OnInit,  AfterViewInit,  OnDestroy,  ViewChild,  ElementRef,  ChangeDetectorRef} from '@angular/core';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/_services/account.service';
import { CardDetails, RegisterRequestModel  } from 'src/app/models/loginrequest';
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';
import { Location } from '@angular/common';
import { TransferdataService } from "src/app/transferdata.service";
import {CreateTransactionRequest } from 'src/app/models/CreateTransactionRequest';
import * as moment from 'moment';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

import { format } from 'path';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
  
})
export class PaymentComponent implements OnInit,AfterViewInit, OnDestroy {
  [x: string]: any;
  //todo 27 sept 23
  date=new Date();
   formattedDate : any;
   
   message:string;
   message1:string;
   approvalText:string;
   finalcostfromSubsUI:any;
   Planvalidity:any='1001-01-01';
   registerdEmail:any;
   registerdEmail1:string;
   addonDevice:string;
   addonPrice:string;
   userPlane:string;
   ValidityofPlan:any;//17nov23

    noOfAdditionalAddonDevice: any;
    additionalAddonDeviceCost:any
    additionalAddonDate: any;
    remainingAmount: any;    
    cancellationPlanDate: any;
    balanceAmount11:any;
    balanceAmount12:any;
    countValue:any


   loginUserId:any;
   plan_qty:any;
   stripe:any;
   card: any;
 
   error: string;
   CS:string;
   amountToPay:number=0;
   numberOfSubscriber:any=2;
   fullName:any;
   edited:boolean=false;
   hidepaybutton :boolean=true;
   showHideRemainingAmount:boolean=false;
   showHideBillingPart:boolean=false;
   showHideProceed:boolean=false;
   finalPrice1: any;
   totalSubscriptionAmount:any;
   finalAmountToPay:any;

   //25sept-23
   
   cardNumber:any;
   cardExpierd:any;
   cardCVV:any;
   NoteValue12:any;
   btnProceedText : string = 'Proceed';
   btnLogOut: string='Logout';
  
  
   //todo29aug-23
  
  //@ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;
  customerId: string;
  
  constructor(private ngxService: NgxUiLoaderService, private route:Router,private service:AccountService,private http: HttpClient,
    private cd: ChangeDetectorRef, private stripeService:AngularStripeService, private appService: TransferdataService , private location: Location) { 
   
  }
 
  ngAfterViewInit() { 
   
  }

  ngOnDestroy() {

    this.Planvalidity=null;
    this.registerdEmail=null;
    this.additionalAddonDate=null;
    this.finalcostfromSubsUI=null;
   
  }
 
  ngOnInit(): void 
   {  
    
    this.location.subscribe(() =>{
      history.pushState(null, this.location.path());
     this.totalSubscriptionAmount='0.0'
    });
   
    
     debugger
     this.appService.currentApprovalStageMessage.subscribe(msg => this.message = msg)
     if(this.message=="Basic device is required!")
     {
      this.message = localStorage.getItem('uMassage') as string;
     }
     else
     {
      
      localStorage.setItem('uMassage', this.message.toString());

     }
     this.appService.selectedPlanPricermessage.subscribe(msg => this.finalcostfromSubsUI = Number(msg).toFixed(2))
     if(this.finalcostfromSubsUI=="NaN")
     {
      this.finalcostfromSubsUI = localStorage.getItem('uFinalPrise') as string;
      this.finalAmountToPay=this.finalcostfromSubsUI;
     }
     else
     {
      this.finalAmountToPay=this.finalcostfromSubsUI;
      localStorage.setItem('uFinalPrise', this.finalcostfromSubsUI.toString());

     }
     this.appService.sendRegisteredEmailIdAsMessage.subscribe(msg => this.registerdEmail=msg )
     if(this.registerdEmail=="N/A")
     {
      this.registerdEmail = localStorage.getItem('uEmail') as string;
     }
     else
     {
      localStorage.setItem('uEmail', this.registerdEmail.toString());

     }
     this.appService.sendLoginUserIdAsMessage.subscribe(msg => this.loginUserId=msg )

     this.appService.sendAddonPriceMessage.subscribe(msg=>this.addonPrice=msg)
        
     if(this.addonPrice=="N/A")
     {
      this.addonPrice = localStorage.getItem('uaddonPrice') as string;
     }
     else
     {
      localStorage.setItem('uaddonPrice', this.addonPrice.toString());

     }
     this.appService.sendAddonDeviceMessage.subscribe(msg => this.addonDevice = msg)
     if(this.addonDevice=="N/A")
     {
      this.addonDevice = localStorage.getItem('uaddonDevice') as string;
     }
     else
     {
      localStorage.setItem('uaddonDevice', this.addonDevice.toString());
     }

     this.appService.sendPlanNameMessage.subscribe(msg => this.userPlane = msg)
     if(this.userPlane=="N/A")
     {
      this.userPlane = localStorage.getItem('uuserPlane') as string;
     }
     else
     {
      localStorage.setItem('uuserPlane', this.userPlane.toString());
     }


     this.appService.sendValidityAsMessage.subscribe(msg => this.Planvalidity=msg)
     // Set now + 30 days as the new date

     if(this.Planvalidity=="N/A")
     {
      this.Planvalidity = localStorage.getItem('uPlanVilidity') as string;
     }
     else
     {
      localStorage.setItem('uPlanVilidity', this.Planvalidity.toString());

     }
      //to get aadditionalAddon
     this.appService.AdditionalAddonDevices1.subscribe(msg =>this.noOfAdditionalAddonDevice=msg)

     if((this.noOfAdditionalAddonDevice=="N/A")&&(this.registerdEmail!="N/A"))
     {
      this.noOfAdditionalAddonDevice = localStorage.getItem('unoOfAdditionalAddonDevice') as string;
      if(this.noOfAdditionalAddonDevice==null)
       {
         this.noOfAdditionalAddonDevice="N/A";
       }
     }
     else
     {
      if(this.noOfAdditionalAddonDevice=="0")
       {
         this.noOfAdditionalAddonDevice="N/A";
       }
      localStorage.setItem('unoOfAdditionalAddonDevice', this.noOfAdditionalAddonDevice.toString());
     }

     this.appService.AdditionalAddonDevicesPrice1.subscribe(msg =>this.additionalAddonDeviceCost=Number(msg).toFixed(2))
    //todo18aug23
     if((this.additionalAddonDeviceCost=="N/A")||(this.additionalAddonDeviceCost=="NaN"))
     {
      
      this.additionalAddonDeviceCost = localStorage.getItem('uadditionalAddonDeviceCost') as string;
      if(this.additionalAddonDeviceCost==null)
      {
        this.additionalAddonDeviceCost="N/A";
      }
     }
     else
     {
      localStorage.setItem('uadditionalAddonDeviceCost', this.additionalAddonDeviceCost.toString());
     }
     //After change a current plan in mid of month
     this.appService.remainingAmountofoldSubscription.subscribe(msg=>this.remainingAmount=Number(msg).toFixed(2))
     if((this.remainingAmount=="NaN")||(this.remainingAmount=="N/A"))
     {
      this.remainingAmount = localStorage.getItem('uremainingAmount') as string;
      this.balanceAmount11=this.remainingAmount;
     }
     else
     {
      localStorage.setItem('uremainingAmount', this.remainingAmount.toString());
     // this.balanceAmount11=this.remainingAmount;
     }
///balance amount  of remaining amount
     this.appService.balanceOfRemainingAmountofoldSubscription.subscribe(msg=>this.balanceAmount12=Number(msg).toFixed(2))
     if((this.balanceAmount12==null) || (this.balanceAmount12=="NaN"))
     {
            this.balanceAmount12 = localStorage.getItem('ubalanceAmount12') as string;
           if(Number(this.balanceAmount12)>0){
           this.balanceAmount11=this.balanceAmount12;
             }
      }
     else{
      localStorage.setItem('ubalanceAmount12', this.balanceAmount12.toString());
      this.balanceAmount11=this.balanceAmount12;

     }
    

     this.appService.adjustedAmountofCurrentSubscriptionSubscription.subscribe(msg=>this.finalPrice1=msg);
     if(this.finalPrice1=="N/A")
     {
      this.finalPrice1 = localStorage.getItem('ufinalPrice1') as string;
     }
     else
     {
      localStorage.setItem('ufinalPrice1', this.finalPrice1.toString());

     }


      if( (this.noOfAdditionalAddonDevice=="NA")||(this.noOfAdditionalAddonDevice=="N/A") || (this.noOfAdditionalAddonDevice=="0") ||(this.additionalAddonDeviceCost=="NaN"))   
      {
        //this.totalSubscriptionAmount="Amount To Pay";
        this.edited = false
        localStorage.removeItem(this.noOfAdditionalAddonDevice)
        this.noOfAdditionalAddonDevice=="N/A"
      }
      else{
        this.edited = true
        this.finalAmountToPay=this.additionalAddonDeviceCost;
       }
      if((this.remainingAmount=="NaN")||(this.remainingAmount=="0.00")||(this.remainingAmount==null))   
      {
        //this.totalSubscriptionAmount="Amount To Pay";
        this.showHideRemainingAmount = false
        localStorage.removeItem(this.remainingAmount)
        this.remainingAmount=="0.00";
        this.showHideBillingPart=true;
      }
     else{

        if(this.finalPrice1>0){

          this.NoteValue12="Amount to pay for the current subscription";
          this.balanceAmount11=0;
          this.showHideBillingPart=true;
          this.showHideProceed=false;

        }
        else{

          this.NoteValue12="Amount credited to the wallet";
          
           if(this.balanceAmount11>0)
           {
            this.finalPrice1=this.balanceAmount11; 
               this.showHideBillingPart=false;
          this.showHideProceed=true;
          this.btnProceedText=='Proceed'
           }
           else{
          this.finalPrice1=Math.abs(this.finalPrice1);
          this.balanceAmount11=this.finalPrice1;
          this.showHideBillingPart=false;
          this.showHideProceed=true;
          this.btnProceedText=='Proceed'
          }
          
        }
       // this.totalSubscriptionAmount="Total Subscription Amount";
      
        this.showHideRemainingAmount = true
        this.finalAmountToPay=this.finalPrice1;
        //this.edited=false
        //this.noOfAdditionalAddonDevice=="N/A"
      }
      //17Nov23
        
      const TempplanValidity= new Date(this.Planvalidity);
      this.Planvalidity= moment(this.Planvalidity).format('MM/DD/YYYY');
      const now= new Date();
      const currentdate=new Date(now);
      this.currentdate= moment(this.currentdate).format('MM/DD/YYYY');
     if( this.Planvalidity==this.currentdate)
      { debugger
       
       this.ValidityofPlan="Subscription start date"
      }
      else
      { 
       this.ValidityofPlan="Subscription valid till"                 
      
      }
     
    
    }
  onChange( error:any ) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = "null";
    }
    this.cd.detectChanges();
  }
  
   logOutClick(){
    debugger
    this.ngxService.start();
    localStorage.clear();  
  
    this.route.navigate(['login']);
    this.ngxService.stop();
  }
  ///27Jan25
  termsAndConditionUrl: string = "https://subscriptionsstagingapis.cyareleaseforms.com/" + "TermsAndCondition/CYA SOFTWARE SERVICE AGREEMENT.pdf";

  // Optional: A method to handle the click event if needed
  handleLinkClick(event: Event): void {
    event.preventDefault(); // Prevent default navigation behavior if any logic is needed
    console.log('Link clicked, opening the Terms and Conditions PDF');
    window.open(this.termsAndConditionUrl, '_blank');  // Open in a new tab
  }
  // Optional: A method to handle the click event if needed


  //todo27/9/23-- This function is used to proceed without taking card detail.
  async registerClick(){
    debugger
      this.customerId="0";
      this.Planvalidity= moment(this.Planvalidity).format('MM/DD/YYYY');
      this.registerSubscribedUser(this.customerId);
      localStorage.clear();
     }

  async  MakePaymentClick(cardNumber:string,cardExpierd:string,CVV:string,ZipCode:string){
    debugger
       var data:CardDetails={
       CardNumber:cardNumber,
       CardExpiry:cardExpierd, 
       CardCVV:CVV,
       ZipCode:ZipCode 
      };  
     this.deleteSafariCookies
       if((cardNumber !="") &&( cardExpierd!="")&&(CVV!="")&&(ZipCode!=""))
       {
       this.Planvalidity= moment(this.Planvalidity).format('MM/DD/YYYY');
              
        this.cardNumber=cardNumber
        this.cardExpierd=cardExpierd
        this.cardCVV=CVV
        
        if(this.loginUserId==7797){
          this.finalAmountToPay='.01';         
        }
       this.sendRequest();
     //}
     }
     else{
       alert(" Card details are required field")
       
     }
    }
    
    //Authorise.net getway integration

    sendRequest()
       { 

        const createTransactionRequest: CreateTransactionRequest = {
          createTransactionRequest: {
            merchantAuthentication: {
                name: '2323SUGvWKw5',
                transactionKey: '3bcC9rAe6H38699v'
                // name: '2323SUGvWKw5',
              //  transactionKey: '3bcC9rAe6H38699v'

              // Local Credential
          //  name: '3c3BQ5zG',
          //   transactionKey: '5M2b4wW2pwe84ZF4'
            },
            transactionRequest: {
              transactionType: 'authCaptureTransaction',
             // amount: '.01',
              amount: this.finalAmountToPay,
              payment: {
                creditCard: {
                  cardNumber: this.cardNumber,
                  expirationDate: this.cardExpierd,
                   cardCode: this.cardCVV
                }
              }
            }
          }
        };
        

    //   debugger
    //  var createTransactionRequest: CreateTransactionRequest=
    //       {
        
    //   createTransactionRequest:
    //    {
    //     merchantAuthentication:
    //      {

    //       //ClientCredential 
    //       // name: '2323SUGvWKw5',
    //       // transactionKey: '388hUPh8J7KC66kx'

    //      // Local Credential
    //        name: '3c3BQ5zG',
    //        transactionKey: '5M2b4wW2pwe84ZF4'
    //      },
    //      transactionRequest: {
    //       transactionType: 'authCaptureTransaction',
    //       // amount: "$.01"this.finalAmountToPay"",
    //        amount: ".01",
    //       payment: {
    //         creditCard: {
    //           cardNumber: this.cardNumber,
    //           expirationDate: this.cardExpierd,
    //          // cardCode: ""
    //         }
    //       }
    //     }
    //   }
    //  };
      
     debugger
     this.ngxService.start();
     var response = this.service.CallAuthorizeNetMethod(createTransactionRequest).subscribe(res=>{
      debugger
              // console.log("register user data API",res.messages.message[0].text)
     
       if (res != null && res.messages.resultCode == 'Ok')
            {
                if (res.transactionResponse != null)
                {
                  this.customerId=res.transactionResponse.transId;
                  this.registerSubscribedUser(this.customerId);
                 // alert(res.transactionResponse.messages[0].description);
                 }
                }
            else if(res != null) //To show error message
            { 
               if(res.messages.message[0].code="E00003"){
                alert(res.transactionResponse.errors[0].errorText);
               // alert('Please check the card details.')
                }
                else{
                  alert(res.transactionResponse.errors[0].errorText);
                }
                if (res.transactionResponse != null) 
                {
                    alert(res.transactionResponse.errors[0].errorText);
                }
            }
            else{
              alert('Please check the card details.')
            }

          });
          this.ngxService.stop();
    }
   
  
     private async registerSubscribedUser(customerId:any){
     debugger
     if(this.balanceAmount11==null){
      this.balanceAmount11=0
     }
     var data:RegisterRequestModel={
      
      UserId :this.loginUserId,
      EmailId : this.registerdEmail,
      SubId: 1,
      FirstName : "test",
       Price : this.finalcostfromSubsUI,
       PaymentToken:" ",
      NumberOfSubscriber : this.message,
      AutoPayment : true,
      FreeTrialRenewalFlag : false,
      PartialPaymentFlag : true,
      Validity :this.Planvalidity,
      PlanName: this.userPlane,
      AddonPrice: this.addonPrice,
      AddonDevice: this.addonDevice,
      ZipCode: "120011",
      Country: "string",
      State: "string",
       City: "string",
       Address : "string", 
       PaymentCustomerId:customerId,
      //added Field for  Additional-Addon
       AdditionalAddonDevices: this.noOfAdditionalAddonDevice,
       AdditionalAddonDevicesCost: this.additionalAddonDeviceCost,
       Additionaladdondate: " ",
       CancellationDate: " ",
       RemainingAmount: this.balanceAmount11,
      };
      //var response1 = this.service.registerUser(data).subscribe();
      //var getpi=response1;
      this.ngxService.start();
      var response = this.service.registerUser(data).subscribe(async res=>{
      console.log("register user data API",res);
      //alert(JSON.stringify(res));

      if(res.ResponseCode==200){

        alert("Renewal was successfully done. Please close browser (Chrome/ Safari) completely and relaunch the CYA Application.");
          this.noOfAdditionalAddonDevice= 0;
          this.route.navigate(['login']);
            
            }
      else if(res.ResponseCode==400){
        alert("Unauthorized user");
      } 
      else{
        alert("Internal Server Error");
      }

    }
    ,(err:HttpErrorResponse)=>{
      alert("Exception occured");
    })
    this.ngxService.stop();
  }
  
  
  //  Alphabatic Validation function.
   onKeypressEvent(event: KeyboardEvent) {
    debugger
    console.log(event);
    let keyPress = event.which;
    console.log(keyPress);
     if ((keyPress >= 33 && keyPress <= 64 ) ||(keyPress >= 91 && keyPress <= 96 ) ||(keyPress >=123 && keyPress <= 127 ))
     {
      event.preventDefault();
    }

   }
   //for numeric

   numberOnly(event: { which: any; keyCode: any; }): boolean {
    let charCode1=event.which;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode1 > 31 && (charCode1 < 48 || charCode1 > 57)) {
      return false;
    }
    return true;

   } 
   

   deleteSafariCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name.trim() === '_ga') {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.example.com;';
      }
     }
    }

    

}

