export interface Loginrequest {
    EmailId:string,
    Password:string
}

export class FreeTrialRegisterRequest {
      UserId : number;
      EmailId : string;
      SubId: number;
      FirstName : string;
      //LastName : string;
      IsActive : boolean;
      Password : string;
      Price : any;
      PaymentToken: "string";
      SubscriptionPriceId : number; 
      NumberOfSubscriber : any;
      AutoPayment : boolean;
      IsFreeTrial : boolean;
      IsBuySubscription : boolean;
      ContactNumber : number;
      IsPasswordService : boolean;
      PlanName: "string";
       AddonPrice: "string";
       AddonDevice: "string"

     
}

export class RegisterRequestModel {
   
      UserId : number;
      EmailId : string;
      SubId: number;
      FirstName : string;
      Price : any;
      PaymentToken: string;
      NumberOfSubscriber : any;
      AutoPayment : boolean;
      FreeTrialRenewalFlag : boolean;
      PartialPaymentFlag : boolean;
      Validity : string;
      PlanName: string;
      AddonPrice: string;
      AddonDevice: string;
      ZipCode: string;
      Country: string;
      State: string;
      City: string;
      Address : string;
      PaymentCustomerId: string;
      //added for additional Addon
      AdditionalAddonDevices: string;
      AdditionalAddonDevicesCost: string;
      Additionaladdondate: any;
      CancellationDate:any;
      RemainingAmount:any;

}


export class CreateStripeCustomerRequest{
    Country : string;
    State: string;
    City: string;
    ZipCode: string;
   // Mailingaddress: string;
    Name: string;
    EmailId: string;
    PaymentToken: string;
}
 
  
  export class TransactionResponse {
    responseCode: string;
    authCode: string;
    avsResultCode: string;
    cvvResultCode: string;
    cavvResultCode: string;
    transId: string;
    refTransID: string;
    transHash: string;
    testRequest: string;
    accountNumber: string;
    accountType: string;
    transHashSha2: string;
    supplementalDataQualificationIndicator: number;
    networkTransId: string;
  }
  export class CardDetails{
    CardNumber:any;
    CardExpiry: any;
     CardCVV: any;
    //   Country : string;
    // State: string;
    // City: string;
     ZipCode: string;
  
    // Name: string;
    
}

 
  

  
  
 
                
         
 


