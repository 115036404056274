
  <script src="assets/js/loader.js"></script>
  <script src="assets/js/index.js"></script>
  
  <!-- left section start -->
  <section id="sidebar-section">
    <div class="sidebar-toggle-icon">
      <i class="fa fa-times" id="hide"></i>
      <img src="assets/img/image002.png">
    </div>
    <div class="sidebar-menu">
      <ul>
        <li class="active"><a href="#"><i class="fa fa-columns sidebar-left-icon" id="icon-one"></i><span id="text-home">dashboard</span></a></li>
        <li><a href="#"><i class="fa fa-users sidebar-left-icon"></i><span>customers</span></a></li>
        <li><a href="#"><i class="fa fa-refresh sidebar-left-icon"></i><span>Renew-Subscription</span></a></li>
        <li><a href="#"><i class="fa fa-money sidebar-left-icon"></i><span>Transactions</span></a></li>
        <li><a href="#"><i class="fa fa-address-card sidebar-left-icon"></i><span>Contact Us</span></a></li>
        <!-- <li><a href="#"><i class="fa fa-mobile sidebar-left-icon"></i><span>application</span></a></li> -->
        <!-- <li><a href="#"><i class="fa fa-cog sidebar-icon sidebar-left-icon"></i><span>widget</span></a></li> -->
        <!-- <li><a href="#"><i class="fa fa-wpforms sidebar-left-icon"></i><span>forms</span></a></li> -->
        <!-- <li><a href="#"><i class="fa fa-pie-chart sidebar-left-icon"></i><span>chart</span></a></li> -->
      </ul>
    </div>
  </section>
  <!-- left section end -->
  <section id="right-section"> 
  <div class="container-fluid header-section">
    <div class="header-toggle">
      <i class="fa fa-bars" id="show"></i>
    </div>
    <div class="header-icon">
      <div class="header-area">
      <i class="fa fa-envelope"></i>
          <i class="fa fa-bell"></i>
          <div class="circle-header">
          <img src="assets/img/user.jpg">
          <div class="tooltiptext">
            <h4>welcome admin</h4>
            <p>traver james</p>
            <div class="user-profile-details">
              <ul>
                <li><a href="#"><span><i class="fa fa-user profile"></i></span> My profile </a></li>
                <li><a href="#"><i class="fa fa-cog profile"></i>setting </a></li>
                <li><a href="#"> <i class="fa fa-sign-out profile"></i>logout </a></li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  </div>
  </div>
  <div class="container-fluid widget-section">
    <div class="col-md-12">
    <div class="row widget-row">
      <div class="col-md-3">
        <div class="widget-box-one">
          <div class="widget-text">
          <h2>total income</h2>
          <h3>$579,000</h3>
          <h5>saved 25%</h5>  
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="widget-box-two">
          <div class="widget-text">
          <h2>total expences</h2>
          <h3>$79,000</h3>
          <h5>saved 25%</h5>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="widget-box-three">
          <div class="widget-text">
          <h2>cash on hand</h2>
          <h3>$92,000</h3>
          <h5>saved 25%</h5>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="widget-box-four">
          <div class="widget-text">
          <h2>net profit margin</h2>
          <h3>$179,000</h3>
          <h5>saved 65%</h5>
          </div>
        </div>
      </div>
    </div>
    </div>

<!--chart-->
<div class="col-md-12">
  <div class="row">
    <div class="col-md-4">
      <div id="bar-chart"></div>
    </div>
    
    <div class="col-md-8">
      <div id="line-chart"></div>
    </div>
  </div>
</div>
<!---->


    <div class="row mt-4">
       <div class="col-lg-6 col-md-6">       
        <div class="table-cart">
        <div class="table-responsive">        
          <table class="table border-less">
            <thead>
              <tr>
                <th colspan="4">user details</th>
              </tr>
            </thead>
            <thead>
              <th class="tbl-head">ID</th>
              <th class="tbl-head">Content</th>
              <th class="tbl-head">Date</th>
              <th class="tbl-head">Action</th>
            </thead>
            <tbody>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
            </tbody>
          </table>
          </div>
        </div>   
       </div>
       <div class="col-lg-6 col-md-6">        
         <div class="table-cart">
         <div class="table-responsive">        
          <table class="table border-less">
            <thead>
              <tr>
                <th colspan="4">user details</th>
              </tr>
            </thead>
            <thead>
              <th class="tbl-head">ID</th>
              <th class="tbl-head">Content</th>
              <th class="tbl-head">Date</th>
              <th class="tbl-head">Action</th>
            </thead>
            <tbody>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
              <tr>
                <td>123</td>
                <td>esabella christensen <p> lorem ispum is simply...</p></td>
                <td>11 may 12:56</td>
                <td><button>reject</button> <button class="approve">approve</button></td>               
              </tr>
            </tbody>
          </table>
         </div> 
        </div>
       </div>
    </div>
  </div>    
  </section>

  <!-- <script src="assets/js/loader.js"></script> -->

 